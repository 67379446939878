import { api_url } from './global-props';
import { doRequest } from './models/account';

export function login(login) {
  let url = api_url + '../token';
  let body = Object.keys(login).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(login[key])).join('&');
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded'}
  const method = 'POST';
  return fetch(url, { method: method, body: body, headers: headers, credentials: 'include' })
    .then(response=>response.json());
}

export function logout() {
  let url = api_url + 'account/logout';
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, headers: headers });
}

export function register({email=null, password=null, confirmPassword=null}) {
  let url = api_url + 'account/register';
  let body = JSON.stringify({Email: email, Password: password, ConfirmPassword: confirmPassword});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function setUserPassword(userId, password, confirmPassword) {
  let url = api_url + 'account/setpassword';
  let body = JSON.stringify({userId: userId, NewPassword: password, ConfirmPassword: confirmPassword});
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function resetPassword(reset) {
  let url = api_url + 'account/resetpassword';
  let body = JSON.stringify(reset);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function forgotPassword(email) {
  let url = api_url + 'account/forgotpassword?email=' + email;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, headers: headers });
}

export function getSettings() {
  let url = api_url + 'settings';
  return doRequest(url);
}

export function getTenants() {
  let url = api_url + 'tenants/list';
  return doRequest(url);
}

export function getTenant(id) {
  let url = api_url + 'tenants/' + id;
  return doRequest(url);
}

export function getTenantUnlockList(id) {
  let url = api_url + 'tenants/' + id + '/unlocklist';
  return doRequest(url);
}

export function tenantUnlock(id, data) {
  let url = api_url + 'tenants/' + id + '/unlock';
  let body = JSON.stringify(data);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function patchTenant(id, patch) {
  let url = api_url + 'tenants/' + id;
  let body = JSON.stringify(patch);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PATCH';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function removeAccessPrivilegeGroup(tenantId, apgId) {
  let url = api_url + 'tenants/' + tenantId + '/accessprivilegegroups/' + apgId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'DELETE';
  return doRequest(url, { method: method, headers: headers });
}

export function updateAccessPrivilegeGroup(tenantId, apgId) {
  let url = api_url + 'tenants/' + tenantId + '/accessprivilegegroups/' + apgId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PUT';
  return doRequest(url, { method: method, headers: headers });
}

export function addTenantUser(tenantId, userId, role) {
  let url = api_url + 'tenants/' + tenantId + '/roles/' + userId + '/' + role;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PUT';
  return doRequest(url, { method: method, headers: headers });
}

export function removeTenantUser(tenantId, userId) {
  let url = api_url + 'tenants/' + tenantId + '/roles/' + userId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'DELETE';
  return doRequest(url, { method: method, headers: headers });
}

export function getCompanyFieldOptions(resync=false) {
  let url = api_url + 'settings/companyfieldoptions?resync=' + resync;
  return doRequest(url);
}

export function getAccessPrivilegeGroup(resync=false) {
  let url = api_url + 'settings/accessprivilegegroups?resync=' + resync;
  return doRequest(url);
}

export function resyncCardholders() {
  let url = api_url + 'settings/resynccardholders';
  return doRequest(url);
}

export function resyncMeetings() {
  let url = api_url + 'settings/resyncmeetings';
  return doRequest(url);
}

export function findCardholder(cardCode=null, pinCode=null) {
  let url = api_url + 'cardholders/find/'
  if(cardCode!==null){
    url = url += '?cardCode=' + cardCode;
  } else if(pinCode!==null){
    url = url += '?pinCode=' + pinCode;
  }
  return doRequest(url);
}

export function getTenantCardholders(tenantId, activeOnly=false) {
  let url = api_url + 'cardholders/list?tenantid=' + tenantId + '&activeonly=' + activeOnly;
  return doRequest(url);
}

export function getCardholder(id) {
  let url = api_url + 'cardholders/' + id;
  return doRequest(url);
}

export function patchCardholder(cardholderId, tenantId, patch) {
  let url = api_url + 'cardholders/' + cardholderId + '?tenantid=' + tenantId;
  let body = JSON.stringify(patch);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PATCH';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function updateCardholderAccessPrivilegeGroup(cardholderId, apgId) {
  let url = api_url + 'cardholders/' + cardholderId + '/accessprivilegegroups/' + apgId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PUT';
  return doRequest(url, { method: method, headers: headers });
}

export function removeCardholderAccessPrivilegeGroup(cardholderId, apgId) {
  let url = api_url + 'cardholders/' + cardholderId + '/accessprivilegegroups/' + apgId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'DELETE';
  return doRequest(url, { method: method, headers: headers });
}

export function getAdminUsers() {
  let url = api_url + 'users/getadminusers';
  return doRequest(url);
}

export function getUsers() {
  let url = api_url + 'users/list';
  return doRequest(url);
}

export function getUser(id) {
  let url = api_url + 'users/' + id;
  return doRequest(url);
}

export function changeUserRole(userId, role) {
  let url = api_url + 'users/' + userId + '/roles/' + role;
  let body = null;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PUT';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function deleteUser(userId) {
  let url = api_url + 'users/' + userId;
  const method = 'DELETE';
  return doRequest(url, { method: method});
}

export function getMeetingSpaces() {
  let url = api_url + 'meetings/spaces';
  return doRequest(url);
}

export function getMeetingSpace(spaceId) {
  let url = api_url + 'meetings/spaces/' + spaceId;
  return doRequest(url);
}

export function getMeetings(tenantId=null, dtFrom=null, dtTo=null, meetingSpaceId=null) {
  let url = api_url + 'meetings/list?tenantId=' + tenantId;
  if (dtFrom!==null) {
    url = url + '&dtFrom=' + dtFrom;
  }
  if (dtTo!==null) {
    url = url + '&dtTo=' +  dtTo;
  }
  if(meetingSpaceId){
    url = url + '&meetingSpaceId=' + meetingSpaceId;
  }
  return doRequest(url);
}

export function getAvailableMeetings(dt, replacingMeetingId) {
  let url = api_url + 'meetings/availablemeetings?dt=' + dt;
  if(replacingMeetingId){
    url = url + "&replacingmeetingid=" + replacingMeetingId
  }
  return doRequest(url);
}

export function getMeeting(id) {
  let url = api_url + 'meetings/' + id;
  return doRequest(url);
}

export function patchMeeting(id, patch) {
  let url = api_url + 'meetings/' + id;
  let body = JSON.stringify(patch);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PATCH';
  return doRequest(url, { method: method, body: body, headers: headers });
}

export function updateMeetingAccessPrivilegeGroup(meetingId, apgId) {
  let url = api_url + 'meetings/' + meetingId + '/accessprivilegegroups/' + apgId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'PUT';
  return doRequest(url, { method: method, headers: headers });
}

export function removeMeetingAccessPrivilegeGroup(meetingId, apgId) {
  let url = api_url + 'meetings/' + meetingId + '/accessprivilegegroups/' + apgId;
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'DELETE';
  return doRequest(url, { method: method, headers: headers });
}

export function meetingNotifications(id, data) {
  let url = api_url + 'meetings/' + id + '/meetingnotifications';
  let body = JSON.stringify(data);
  const headers = { 'Content-Type': 'application/json; charset=utf-8'}
  const method = 'POST';
  return doRequest(url, { method: method, body: body, headers: headers });
}
