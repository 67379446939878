import React, { useState, useEffect } from 'react';
import { appAuth } from "../router";
import { useNavigate, useLocation } from 'react-router-dom';
import { findCardholder, getTenantCardholders, patchCardholder } from '../models/cardholders';
import { getTenant } from '../models/tenants';
import { resyncCardholders } from '../models/settings';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalBody } from 'reactstrap';
import { useForm } from "react-hook-form";
import Button from '../components/button';
import Loader from '../components/loader';
import FormatDate from '../components/formatDate';
import PageHeader from '../components/pageHeader';
import TableSearch from '../components/tableSearch';

const Cardholders = (props) => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [tenantName, setTenantName] = useState(null);
  const [cardholders, setCardholders] = useState([]);
  const [filteredCardholders, setFilteredCardholders] = useState([]);
  const [cardholderInput, setCardholderInput] = useState({
    firstName: "",
    lastName: "",
    cardCode: ""
  });
  const [existingAddCardholder, setExistingAddCardholder] = useState(null);
  const [loadedExistingCardholder, setLoadedExistingCardholder] = useState(false);
  const [isSavingCardholder, setIsSavingCardholder] = useState(false);
  const [isDeactivatingCardholder, setIsDeactivatingCardholder] = useState(false);
  const [addCardholderModal, setAddCardholdersModal] = useState(false);
  const [deleteCardholderModal, setDeleteCardholderModal] = useState(false);
  const [deleteCardholder, setDeleteCardholder] = useState(false);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [addCardholderError, setAddCardholderError] = useState(null);
  const [deleteCardholderError, setDeleteCardholderError] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    retrieveCardholders();
    if(!(state && state.tenantName)){
      retrieveTenant();
    }
  }, [])

  const retrieveTenant = () => {
    getTenant(id)
      .then(
        result => {
          setError(null);
          setTenantName(result.name);
        },
        error => {
          setError(error);
        }
      )
  }

  const retrieveCardholders = () => {
    getTenantCardholders(id)
      .then(
        result => {
          setIsLoaded(true);
          setCardholders(result);
          setFilteredCardholders(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const syncCardholder = () => {
    setIsSyncing(true);
    resyncCardholders()
    .then(result => {
      setIsSyncing(false);
      retrieveCardholders();
    })
  }

  const modalCardholderToggle = () => {
    setAddCardholdersModal(!addCardholderModal);
    setLoadedExistingCardholder(false);
    setExistingAddCardholder(null);
    setCardholderInput({
      firstName: "",
      lastName: "",
      cardCode: ""
    })
    reset();
  }

  const handleCardholderInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setCardholderInput(preValue => { return {...preValue, [name]: value}})
  }

  const loadExistingCardholder = () => {
    let existingCardholder = {
      firstName: existingAddCardholder.firstName,
      lastName: existingAddCardholder.lastName,
      cardCode: existingAddCardholder.cardCode
    }
    setLoadedExistingCardholder(true);
    setCardholderInput(existingCardholder);
  }
  
  const handleSaveCardholder = (data) => {
    findCardholder(data.cardCode)
    .then(result => {
      if(!result.length){
        setExistingAddCardholder(null);
        setAddCardholderError(null);
        setIsSavingCardholder(true);
        data = {firstName: data.firstName, lastName: data.lastName, cardCode: data.cardCode}
        patchCardholder(0, id, data)
        .then( result => {
          setIsSavingCardholder(false);
          navigate(`/cardholders/${result.id}/edit`, {state: {tenantId: id, tenantName:(state&&state.tenantName)||tenantName}});
        },
        error => {
          setError(error);
        })
      } else {
        if(loadedExistingCardholder){
          setExistingAddCardholder(null);
          setAddCardholderError(null);
          setIsSavingCardholder(true);
          data = {firstName: data.firstName, lastName: data.lastName, cardCode: data.cardCode, active: true}
          patchCardholder(existingAddCardholder.id, id, data)
          .then( result => {
            setIsSavingCardholder(false);
            navigate(`/cardholders/${result.id}/edit`, {state: {tenantId: id, tenantName:(state&&state.tenantName)||tenantName}});
          },
          error => {
            setError(error);
          })
        } else {
          setExistingAddCardholder(result[0]);
        }
      }
    }, error => {
      setAddCardholderError(error);
    })
  }

  const deactivateCardholder = (e, cardholder) => {
    setIsDeactivatingCardholder(true);
    patchCardholder(cardholder.id, id, {active: !cardholder.active})
    .then( result => {
      setIsDeactivatingCardholder(false);
      retrieveCardholders();
      modalDeleteCardholderToggle();
      setDeleteCardholderError(null);
    },
    error => {
      setIsDeactivatingCardholder(false);
      setDeleteCardholderError(error);
    })
  }

  const modalDeleteCardholderToggle = (e, cardholder) => {
    setDeleteCardholder(cardholder?cardholder:null);
    setDeleteCardholderModal(!deleteCardholderModal);
  }

  const updateFilteredCardholders = (updatedFilteredCardholders) => {
    setFilteredCardholders(updatedFilteredCardholders);
  }

  appAuth.checkAuthorization();
  let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
  let hasInactive = cardholders&&cardholders.some(cardholder=>!cardholder.active);
  return <div className={"container page-tenants"}>
    <div className="content">
      <PageHeader pageName="Cardholders" />
      <div className="page-center">
        <div className="row justify-content-between mb-4 mb-md-2">
          <div className="col-md-3">{isSuperAdmin?<Link className="d-none d-md-block poppins" to={(state&&state.comingFrom==="view")||!(state&&state.comingFrom)?`/tenants/${id}`:`/tenants/${id}/edit`}><FontAwesomeIcon className="me-1" icon="arrow-left"/>Back to tenant</Link>:null}</div>
          <div className="title col-12 col-md-6">Manage {(state&&state.tenantName)||tenantName} Cardholders</div>
          <div className="col-md-3 text-end"><span onClick={syncCardholder} className="ms-3 add-btn sync-button">Sync <FontAwesomeIcon className="ms-4" icon="rotate" spin={isSyncing?true:false}/></span></div>
        </div>
        {error?<Alert color="danger">{error}</Alert>:null}
        <TableSearch list={cardholders} displayFilteredList={updateFilteredCardholders} filterBy={"name"} />
        <div className="apg-table">
          <table>
            <thead>
              <tr>
                <th className="w-50 fw-normal libre">Cardholder <div className="d-md-none small libreItalic">Status|Valid Until</div></th>
                <th className="d-none d-md-th w-25 fw-normal libreItalic">Status</th>
                <th className="d-none d-md-th w-25 fw-normal libreItalic">Valid Until</th>
                <th className="text-end pb-3 fw-normal">{isSuperAdmin?<span onClick={modalCardholderToggle} className="add-btn">Add Cardholder <FontAwesomeIcon className="ms-4" icon="plus"/></span>:null}</th>
              </tr>
            </thead>
            <tbody>
              {isLoaded?
              filteredCardholders.length?
                filteredCardholders.map((cardholder, i) => {
                  let needSync = (new Date(cardholder.dateUpdated) > new Date(cardholder.accessControlLastSync)) || !cardholder.accessControlLastSync || cardholder.accessControlLastSyncError;
                  return cardholder.active || (includeInactive && !cardholder.active)? 
                  <tr key={i} className={!cardholder.active?"inactive":""}>
                    <td className="libre">
                      {cardholder.name}
                      <div className="mt-1 small libreItalic d-md-none">{cardholder.active?"Active":"Inactive"}{cardholder.endDate?" | " + cardholder.endDate:""}</div>
                    </td>
                    <td className="d-none d-md-td libreItalic">
                      {cardholder.active?"Active":"Inactive"} 
                      {needSync?<FontAwesomeIcon className="ms-1" icon="triangle-exclamation"/>:null}
                    </td>
                    <td className="d-none d-md-td libreItalic">{<FormatDate date={cardholder.endDate}/>}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        {cardholder.active?<Link className="action me-2" state={{tenantId: id, tenantName:(state&&state.tenantName)||tenantName}} to={`/cardholders/${cardholder.id}/edit`}><FontAwesomeIcon icon="pen"/></Link>:null}
                        <div className="action" onClick={() => modalDeleteCardholderToggle(null, cardholder)}>{cardholder.active?<FontAwesomeIcon icon="trash-can"/>:<FontAwesomeIcon icon="toggle-on"/>}</div>
                      </div>
                    </td>
                  </tr>
                :null
                })
              :null
              :<tr><td className="pt-4" colSpan="4"><Loader /></td></tr>}
            </tbody>
          </table>
          {hasInactive?<div className="mt-3 poppins cursor-pointer" onClick={() => setIncludeInactive(!includeInactive)}>{includeInactive?"Hide Inactive":"Include Inactive"}</div>:null}
        </div>
      </div>
    </div>
    <Modal isOpen={addCardholderModal} toggle={modalCardholderToggle} centered={true} modalClassName="modal-add-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Add Cardholder</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalCardholderToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {addCardholderError?<Alert color="danger">{addCardholderError}</Alert>:null}
        {existingAddCardholder?
          <Alert color="warning">
          {loadedExistingCardholder?
            <div>Loaded Existing Cardholder</div>
          :
            <>
            <div>There is an existing cardholder with this Card Code.</div>
            <div>If there is a mistake in the Card Code please re-enter and try again.</div>
            <div className="text-center"><Button className="btn btn-primary mt-2" onClick={loadExistingCardholder}>Load Existing Cardholder</Button></div>
            </>
           } 
           </Alert>
        :null}
        <form className="row" onSubmit={handleSubmit(handleSaveCardholder)}>
          <div className="form-group col-sm">
            <label htmlFor="name">First Name</label>
            <input type="text" {...register("firstName", { required: true })} className={"form-control " + (errors.firstName?"invalid":"") } onChange={handleCardholderInputChange} value={cardholderInput.firstName} name="firstName" />
          </div>
          <div className="form-group col-sm">
            <label htmlFor="name">Last Name</label>
            <input type="text" {...register("lastName", { required: true })} className={"form-control " + (errors.lastName?"invalid":"") } onChange={handleCardholderInputChange} value={cardholderInput.lastName} name="lastName" />
          </div>
          <div className="form-group col-12 mt-2">
            <label htmlFor="location">Card #</label>
            <input type="text" {...register("cardCode", { required: true, pattern: {
            value: /\d{2,3}-\d{4,5}/,
            message: "Entered value does not card format ###-#####",
          }, })} className={"form-control " + (errors.cardCode?"invalid":"")} 
          onChange={handleCardholderInputChange}
          value={cardholderInput.cardCode}
          disabled={loadedExistingCardholder}
          name="cardCode"/>
          </div>
          {errors.cardCode&&errors.cardCode.message?<div className="validation-error mt-2">{errors.cardCode.message}</div>:null}
        </form>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto" onClick={handleSubmit(handleSaveCardholder)} loading={isSavingCardholder}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <Modal isOpen={deleteCardholderModal} toggle={modalDeleteCardholderToggle} centered={true} modalClassName="modal-delete-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">{deleteCardholder&&deleteCardholder.active?"Deactivate":"Activate"} Cardholder</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalDeleteCardholderToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {deleteCardholderError?<Alert color="danger">{deleteCardholderError}</Alert>:null}
        <div className="poppins">Are you sure you want to {deleteCardholder&&deleteCardholder.active?"deactivate":"activate"} {deleteCardholder&&deleteCardholder.firstName} {deleteCardholder&&deleteCardholder.lastName}?</div>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto" onClick={() => deactivateCardholder(null, deleteCardholder)} loading={isDeactivatingCardholder}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </div>
}
export default Cardholders;