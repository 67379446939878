import React, { useState, useEffect } from 'react';
import { getTenants, addTenantUser, removeTenantUser } from '../models/tenants';
import { getUsers, getUser, changeUserRole, deleteUser } from '../models/users';
import { registerUser, setUserPassword } from '../models/account';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalBody } from 'reactstrap';
import { useForm } from "react-hook-form";
import Button from '../components/button';
import Loader from '../components/loader';
import PageHeader from '../components/pageHeader';
import TableSearch from '../components/tableSearch';

const Users = (props) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2} = useForm();
  const [isLoaded, setIsLoaded] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isSettingPassword, setIsSettingPassword] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState(false);
  const [selectedEditUser, setSelectedEditUser] = useState(false);
  const [addUserError, setAddUserError] = useState(null);
  const [deleteUserError, setDeleteUserError] = useState(null);
  const [setPasswordError, setSetPasswordError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    retrieveUsers();
    retrieveTenants();
  }, [])

  const retrieveTenants = () => {
    getTenants()
      .then(
        result => {
          setTenants(result);
          setError(null);
        },
        error => {
          setError(error);
        }
      )
  }

  const retrieveUsers = () => {
    getUsers()
      .then(
        result => {
          setIsLoaded(true);
          setUsers(result);
          setFilteredUsers(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const modalUserToggle = () => {
    setAddUserModal(!addUserModal);
    reset();
  }
  
  const handleAddUser = (data) => {
    setIsAddingUser(true);
    registerUser(data.email, data.password, data.password)
    .then( result => {
    },
    error => {
      setAddUserError(error);
      setIsAddingUser(false);
    })
  }
  
  const modalDeleteUserToggle = (e, user) => {
    setSelectedDeleteUser(user?user:null);
    setDeleteUserModal(!deleteUserModal);
  }

  const handleDeleteUser = (id) => {
    setIsDeletingUser(true);
    deleteUser(id)
    .then( result => {
      setIsDeletingUser(false);
      setDeleteUserError(null);
      setDeleteUserModal(false);
      retrieveUsers();
    },
    error => {
      setDeleteUserError(error);
      setIsDeletingUser(false);
    })
  }

  const handleSetPassword = (data) => {
    setIsSettingPassword(true);
    if(selectedEditUser){
      setUserPassword(selectedEditUser.id, data.password, data.confirmPassword)
      .then( result => {
        setIsSettingPassword(false);
        setSetPasswordError(null);
        setEditUserModal(false);
        retrieveUsers();
      },
      error => {
        setSetPasswordError(error);
        setIsSettingPassword(false);
      })
    }
  }

  const modalEditUserToggle = (e, user) => {
    if(!tenants.length){
      retrieveTenants();
    }
    if(editUserModal){
      retrieveUsers();
    }
    setSelectedEditUser(user?user:null);
    setEditUserModal(!editUserModal);
    reset2();
  }

  const updateUserTenants = (tenant) => {
    if(selectedEditUser.Tenants.some(tnt => tnt.tenantId === tenant.id)){
      removeTenantUser(tenant.id, selectedEditUser.id)
      .then( result => {
        getUser(selectedEditUser.id)
        .then(result => {
          setSelectedEditUser(result);
        },
        error => {
          setError(error);
        })
      },
      error => {
        setError(error);
      })
    } else {
      addTenantUser(tenant.id, selectedEditUser.id, "Admin")
      .then( result => {
        getUser(selectedEditUser.id)
        .then(result => {
          setSelectedEditUser(result);
        },
        error => {
          setError(error);
        })
      },
      error => {
        setError(error);
      })
    }
  }

  const updateFilteredUsers = (updatedFilteredUsers) => {
    setFilteredUsers(updatedFilteredUsers);
  }

return <div className={"container page-users"}>
    <div className="content">
      <PageHeader pageName="Users" />
      <div className="page-center">
        <div className="title">Manage Users</div>
        {error?<Alert color="danger">{error}</Alert>:null}
        <TableSearch list={users} displayFilteredList={updateFilteredUsers} filterBy={"userName"} />
        <div className="apg-table">
          <table>
            <thead>
              <tr>
                <th className="w-50 libre">Username <div className="d-block d-md-none small libreItalic">Tenants</div></th>
                <th className="w-25 d-none d-md-th libreItalic">Tenants</th>
                <th className="text-end pb-3"><span onClick={modalUserToggle} className="add-btn">Add User <FontAwesomeIcon className="ms-4" icon="plus"/></span></th>
              </tr>
            </thead>
            <tbody>
              {isLoaded?
              filteredUsers.length?
              filteredUsers.map((user, i) => {
                  return <tr key={i}>
                    <td className="libre break-word">
                      {user.userName}
                      <div className="d-md-none small libreItalic">{user.Tenants.map(tnt=>tnt.tenantName).join(', ')}</div>
                    </td>
                    <td className="d-none d-md-td libreItalic">{user.Tenants.map(tnt=>tnt.tenantName).join(', ')}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <div className="action me-2" onClick={() => modalEditUserToggle(null, user)}><FontAwesomeIcon icon="pen"/></div>
                        <div className="action" onClick={() => modalDeleteUserToggle(null, user)}><FontAwesomeIcon icon="trash-can"/></div>
                      </div>
                    </td>
                  </tr>
                })
              :null
              :<tr><td className="pt-4" colSpan="4"><Loader /></td></tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Modal isOpen={addUserModal} toggle={modalUserToggle} centered={true} modalClassName="modal-add-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Add Cardholder</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalUserToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {addUserError?<Alert color="danger">{addUserError}</Alert>:null}
        <form className="row" onSubmit={handleSubmit(handleAddUser)}>
          <div className="form-group col-sm">
            <label htmlFor="name">Email</label>
            <input type="email" {...register("email", { required: true,pattern: {value: /\S+@\S+\.\S+/,message: "Entered value does not match email format"} })} className={"form-control " + (errors.email?"invalid":"") } name="email" />
          </div>
          <div className="form-group col-sm">
            <label htmlFor="name">Password</label>
            <input type="password" {...register("password", { required: true })} className={"form-control " + (errors.password?"invalid":"") } name="password" />
          </div>
        </form>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto"  onClick={handleSubmit(handleAddUser)} loading={isAddingUser}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <Modal isOpen={deleteUserModal} toggle={modalDeleteUserToggle} centered={true} modalClassName="modal-delete-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Delete User</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalDeleteUserToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {deleteUserError?<Alert color="danger">{deleteUserError}</Alert>:null}
        <div className="poppins">Are you sure you want to delete {selectedDeleteUser&&selectedDeleteUser.userName}?</div>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button onClick={()=> handleDeleteUser(selectedDeleteUser&&selectedDeleteUser.id)} className="btn btn-secondary ms-auto" loading={isDeletingUser}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <Modal isOpen={editUserModal} toggle={modalEditUserToggle} centered={true} modalClassName="modal-delete-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Edit User</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalEditUserToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {setPasswordError?<Alert color="danger">{setPasswordError}</Alert>:null}
        <form className="row" onSubmit={handleSubmit2(handleSetPassword)}>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password"  {...register2("password", { required: true })} id="password" name="password" className={"form-control" + (errors.password?"invalid":"")} />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input type="password"  {...register2("confirmPassword", { required: true })} id="confirmPassword" name="confirmPassword" className={"form-control" + (errors.confirmPassword?"invalid":"")}/>
          </div>
        </form>
        <div className="d-flex mt-3">
          <Button type="secondary" onClick={handleSubmit2(handleSetPassword)} className="btn btn-secondary ms-auto" loading={isSettingPassword}>Save</Button>
        </div>
        <div className="poppins">Add User to Tenant</div>
        <div className="row mt-3">
          {tenants.map(tenant => {
            return <div className={"col-6 col-md-4"}>
              <div className="apg-list d-flex" onClick={() => updateUserTenants(tenant)}>
                <div className={"circle " + (selectedEditUser&&selectedEditUser.Tenants.some(tnt => tnt.tenantId === tenant.id)?"included":"")}>{(selectedEditUser&&selectedEditUser.Tenants.some(tnt => tnt.tenantId === tenant.id)?<FontAwesomeIcon icon="circle-check" />:"")}</div>
                <div>{tenant.name}</div>
              </div>
            </div>
          })}
        </div>
      </ModalBody>
    </Modal>
  </div>
}
export default Users;