import React, { useState, useEffect } from 'react';
import { getCardholder, patchCardholder, updateCardholderAccessPrivilegeGroup, removeCardholderAccessPrivilegeGroup } from './../models/cardholders';
import { resyncCardholders } from '../models/settings';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from 'reactstrap';
import Button from '../components/button';
import Loader from '../components/loader';
import PageHeader from '../components/pageHeader';

const EditCardholder = (props) => {
  let { state } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [manualNeedSync, setManualNeedSync] = useState(false);
  const [cardholder, setCardholder] = useState(null);
  const [editCardholderName, setEditCardholderName] = useState(false);
  const [editCardholderCardCode, setEditCardholderCardCode] = useState(false);
  const [updateCardholderFirstName, setUpdateCardholderFirstName] = useState("");
  const [updateCardholderLastName, setUpdateCardholderLastName] = useState("");
  const [updateCardholderCardCode, setUpdateCardholderCardCode] = useState("");
  const [accessPrivilegeGroups, setAccessPrivilegeGroups] = useState([]);
  const [error, setError] = useState(null);
  const [cardCodeError, setCardCodeError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    retrieveCardholder();
  }, []);

  const retrieveCardholder = () => {
    getCardholder(id)
      .then(
        result => {
          setIsLoaded(true);
          setCardholder(result);
          setError(null);
          let apg = [];
          let tenants = result.tenants;
          tenants.forEach(t => apg = Array.from(new Set([...apg, ...t.tenant.privilegeGroups])));
          setAccessPrivilegeGroups(apg);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const handleUpdateCardholder = (type) => {
    let data;
    let updateMade;
    if(type==="name"){
      data = {firstName: updateCardholderFirstName||cardholder.firstName, lastName: updateCardholderLastName||cardholder.lastName };
      updateMade = !!(updateCardholderFirstName||updateCardholderLastName);
      setEditCardholderName(updateMade);
    } else if(type==="cardCode"){
      if(!updateCardholderCardCode){
        updateMade = false;
        setEditCardholderCardCode(updateMade);
      } else {
        const cardCodePattern = /\d{2,3}-\d{4,5}/;
        cardCodePattern.test(updateCardholderCardCode);
        if(cardCodePattern.test(updateCardholderCardCode)){
          setCardCodeError(null);
          data = {cardCode: updateCardholderCardCode}
          updateMade = !!updateCardholderCardCode;
          setEditCardholderCardCode(updateMade);
        } else {
          setCardCodeError("Entered value does not card format ###-#####");
        }
      }
    }
    if(updateMade){
      patchCardholder(id, 0, data)
      .then( result => {
        setCardholder(result);
        setEditCardholderName(type==="name"?false:editCardholderName);
        setUpdateCardholderFirstName(type==="name"?"":updateCardholderFirstName);
        setUpdateCardholderLastName(type==="name"?"":updateCardholderLastName);
        setEditCardholderCardCode(type==="cardCode"?false:editCardholderCardCode);
        setUpdateCardholderCardCode(type==="cardCode"?"":updateCardholderCardCode);
      },
      error => {
        setError(error);
      })
    }
  }

  const updateAPG = (apgId) => {
    if(cardholder.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apgId)){
      removeAPG(null, apgId);
    } else {
      updateCardholderAccessPrivilegeGroup(cardholder.id, apgId)
      .then( result => {
        setCardholder(cardholder => {return {...cardholder, 'privilegeGroups': result}});
        setManualNeedSync(true);
      },
      error => {
        setError(error);
      })
    }
  }
  
  const removeAPG = (e, apgId) => {
    removeCardholderAccessPrivilegeGroup(cardholder.id, apgId)
    .then( result => {
      setManualNeedSync(true);
      setCardholder(cardholder => {return {...cardholder, 'privilegeGroups': result}});
    },
    error => {
      setError(error);
    })
  }

  const syncCardholder = () => {
    setIsSyncing(true);
    resyncCardholders()
    .then(result => {
      setIsSyncing(false);
      setManualNeedSync(false);
      retrieveCardholder();
    },
    error => {
      setIsSyncing(false);
      setError(error);
    })
  }

  let needSync = cardholder && ((new Date(cardholder.dateUpdated) > new Date(cardholder.accessControlLastSync)) || !cardholder.accessControlLastSync || manualNeedSync);
  return <div className={"container page-edit-tenants"}>
    <div className="content">
      <PageHeader pageName="editCardholder" />
      <div className="page-center">
        <div className="row justify-content-between mb-4">
          <div className="col-md-4">{state&&state.tenantId?<Link className="d-none d-md-block poppins" state={{tenantName:(state&&state.tenantName)}} to={`/tenants/${state.tenantId}/cardholders`}><FontAwesomeIcon className="me-1" icon="arrow-left"/>Back to cardholders</Link>:null}</div>
          <div className="title col-12 col-md-4">Edit Cardholder</div>
          <div className="col-md-4"></div>
        </div>
        {error?<Alert color="danger">{error}</Alert>:null}
        {isLoaded?
        cardholder?
        <>
        <div className="row">
          <div className="col-lg-6">
            <div className="tenant-info">
              <div className="label">Name:</div>
              {editCardholderName?
                <>
                <input className="info form-control me-2" defaultValue={cardholder.firstName} onChange={(e)=>setUpdateCardholderFirstName(e.target.value)} />
                <input className="info form-control" defaultValue={cardholder.lastName} onChange={(e)=>setUpdateCardholderLastName(e.target.value)} />
                </> 
                :<div className="info">{cardholder.firstName} {cardholder.lastName}</div>}
              {editCardholderName?<Button className="btn-secondary ms-3" onClick={()=>handleUpdateCardholder('name')}>Save</Button>:<div className="action" onClick={()=>setEditCardholderName(true)}><FontAwesomeIcon icon="pen"/></div>}
            </div>
            <div className="tenant-info">
              <div className="label">Card #:</div>
              {editCardholderCardCode?<div><input className="info w-100 w-md-50 form-control" defaultValue={cardholder.cardCode} onChange={(e)=>setUpdateCardholderCardCode(e.target.value)} />{cardCodeError?<div className="validation-error">{cardCodeError}</div>:null}</div>:<div className="info">{cardholder.cardCode}</div>}
              {editCardholderCardCode?<Button className="btn-secondary ms-3" onClick={()=>handleUpdateCardholder('cardCode')}>Save</Button>:<div className="action" onClick={()=>setEditCardholderCardCode(true)}><FontAwesomeIcon icon="pen"/></div>}
            </div>
          </div>
        </div>
        <div className="apg-available mt-3">
          <div className="d-flex justify-content-between">
            <div className="mb-3 poppins">APG Accesses {needSync?<FontAwesomeIcon className="ms-1" icon="triangle-exclamation"/>:null}</div>
            {needSync?
              <div className="col-md-4 text-end"><span onClick={syncCardholder} className="ms-3 add-btn sync-button">Sync <FontAwesomeIcon className="ms-4" icon="rotate" spin={isSyncing?true:false}/></span></div>
              :null}
          </div>
          {cardholder.accessControlLastSyncError?<Alert color="danger">Sync Error: {cardholder.accessControlLastSyncError}</Alert>:null}
          <div className="row">
            {accessPrivilegeGroups.map(apg => {
              return <div className={"col-6 col-md-3"}>
                <div className="apg-list d-flex" onClick={() => updateAPG(apg.accessPrivilegeGroupID)}>
                  <div className={"circle " + (cardholder.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apg.accessPrivilegeGroupID)?"included":"")}>{(cardholder.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apg.accessPrivilegeGroupID)?<FontAwesomeIcon icon="circle-check" />:"")}</div>
                  <div>{apg.accessPrivilegeGroup.name}</div>
                </div>
              </div>
            })}
          </div>
          </div>
          </>
          :null
        :<Loader />}
      </div>
    </div>
  </div>
}
export default EditCardholder;