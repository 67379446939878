import React from "react";
import { ReactSVG } from "react-svg";
import LoaderSpinner from '../assets/images/icon-loader-spinner.svg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = (props) => {
    const { className, active, large, lowercase, onClick, loading, disabled, text, children, tag, ...rest } = props;
    let Tag = tag;
    if (!Tag) { Tag = 'button' }
    return <Tag
      className={"btn " + className + (active?" active":"") + (large?" btn-lg":"") + (disabled?" disabled":"") + (lowercase?" text-capitalize":"")}
      onClick={onClick}
      disabled={loading||disabled}
      {...rest}
    >
      {loading?
        <ReactSVG className="loader-spinner" src={LoaderSpinner} />
      :
        <>
          {text}
          {children}
        </>
      }
    </Tag>
}

export default Button;
