import AppRouter from './router.js';
import './App.scss';
import './fontAwesome.js';
import CacheBuster from './components/cachebuster';

function App() {
  return (
    <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        setTimeout(()=>refreshCacheAndReload(),3000);
      }

      return <AppRouter />;
    }}
  </CacheBuster>
  );
}

export default App;
