import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPen,
  faTrashCan,
  faPlus,
  faXmark,
  faCircleCheck,
  faKey,
  faUnlockKeyhole,
  faUser,
  faArrowLeft,
  faRotate,
  faTriangleExclamation,
  faExclamationCircle,
  faToggleOn,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPen,
  faTrashCan,
  faPlus,
  faXmark,
  faCircleCheck,
  faKey,
  faUnlockKeyhole,
  faUser,
  faArrowLeft,
  faRotate,
  faTriangleExclamation,
  faExclamationCircle,
  faToggleOn,
  faChevronLeft,
  faChevronRight,
  );
