import React, { useState, useEffect } from 'react';
import { appAuth } from "../router";
import { getMeeting, updateMeetingAccessPrivilegeGroup, removeMeetingAccessPrivilegeGroup, meetingnotifications } from './../models/meetings';
import { resyncMeetings } from './../models/settings';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
import Loader from '../components/loader';
import Button from '../components/button';
import UpdateMeeting from '../components/updateMeeting';
import PageHeader from '../components/pageHeader';
import Moment from "react-moment";
import moment from "moment";

const Meeting = (props) => {
  let { state } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [manualNeedSync, setManualNeedSync] = useState(false);
  const [meeting, setMeeting] = useState(null);
  const [editMeetingModal, setEditMeetingModal] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [notificationNumbers, setNotificationNumbers] = useState("");
  const [notificationEmails, setNotificationEmails] = useState("");
  const [isSendingNotifications, setIsSendingNotifications] = useState(false);
  const [showSample, setShowSample] = useState(false);
  const [error, setError] = useState(null);
  const { tenantId } = useParams();
  const { meetingId } = useParams();

  useEffect(() => {
    retrieveMeeting();
  }, []);

  const retrieveMeeting = () => {
    getMeeting(meetingId)
      .then(
        result => {
          setMeeting(result);
          setIsLoaded(true);
          setError(null);
        },
        error => {
          setError(error);
          setIsLoaded(true);
        }
      )
  }

  const modalEditMeetingToggle = () => {
    setEditMeetingModal(!editMeetingModal);
  }
  
  const meetingUpdated = () => {
    retrieveMeeting();
    setEditMeetingModal(false);
  }

  const updateAPG = (apgId) => {
    if(meeting.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apgId)){
      removeAPG(null, apgId);
    } else {
      updateMeetingAccessPrivilegeGroup(meeting.id, apgId)
      .then( result => {
        setMeeting(meeting => {return {...meeting, 'privilegeGroups': result}});
      },
      error => {
        setError(error);
      })
    }
  }

  const removeAPG = (e, apgId) => {
    removeMeetingAccessPrivilegeGroup(meeting.id, apgId)
    .then( result => {
      setMeeting(meeting => {return {...meeting, 'privilegeGroups': result}});
    },
    error => {
      setError(error);
    })
  }

  const syncMeeting = () => {
    setIsSyncing(true);
    resyncMeetings()
    .then(result => {
      setIsSyncing(false);
      setManualNeedSync(false);
      retrieveMeeting();
    },
    error => {
      setIsSyncing(false);
      setError(error);
    })
  }

  const sendMeetingNotifications = () => {
    let data;
    if(notificationType==="Email"){
      data = { emails: notificationEmails.split(",")};
    } else if(notificationType==="SMS"){
      data = { numbers:notificationNumbers.split(",")};
    }
    setIsSendingNotifications(true);
    meetingnotifications(meeting.id, data)
    .then(result => {
      retrieveMeeting();
      setNotificationEmails("");
      setNotificationNumbers("");
      setIsSendingNotifications(false);
    },
    error => {
      setError(error);
      setIsSendingNotifications(false);
    })
  }

  appAuth.checkAuthorization();
  let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
  let tenantAdmin = appAuth.authorization&&appAuth.authorization.tenantRoles;
  let needSync = meeting && ((new Date(meeting.dateUpdated) > new Date(meeting.accessControlLastSync)) || !meeting.accessControlLastSync || manualNeedSync);
  return <div className={"container page-view-meeting"}>
    <div className="content">
      <PageHeader pageName="Meeting" />
      <div className="page-center">
        <div className="row justify-content-between mb-4">
          <div className="col-md-4">
            <Link className="d-none d-md-block poppins" to={(state&&state.comingFromAllMeetings)?'/meetings':`/${tenantId}/meetings`}><FontAwesomeIcon className="me-1" icon="arrow-left"/> Back to meetings</Link>
          </div>
          <div className="title col-md-4">View Meeting</div>
          <div className="col-md-4"></div>
        </div>
        {meeting && meeting.status==="Reserved"&&moment(meeting.endTime).isAfter()?
          <div className="mb-3 text-center text-md-end">
            <Button className="btn-primary round-button" onClick={() => modalEditMeetingToggle(true)}>Edit Meeting</Button>
          </div>
        :null}
        {error?<Alert color="danger">{error}</Alert>:null}
        {isLoaded && meeting?
        <>
        <div className="row scrollable">
          <div className="col-12">
            {isSuperAdmin || tenantAdmin.length>1?
              <div className="tenant-info">
                <div className="label">Tenant Name:</div>
                <div className="info">{meeting.tenant&&meeting.tenant.name}</div>
              </div>
            :null}
            <div className="tenant-info">
              <div className="label">Meeting Space:</div>
              <div className="info">{(meeting.meetingSpace&&meeting.meetingSpace.name)||"Internal"}</div>
            </div>
            <div className="tenant-info">
              <div className="label">Date:</div>
              <div className="info"><Moment format="MM/DD/YY">{meeting.startTime}</Moment></div>
            </div>
            <div className="tenant-info">
              <div className="label">Location:</div>
              <div className="info">{(meeting.meetingSpace&&meeting.meetingSpace.location)||"Internal"}</div>
            </div>
            <div className="tenant-info">
              <div className="label">Time:</div>
              <div className="info"><Moment format="h:mma">{meeting.startTime}</Moment> - <Moment format="h:mma">{meeting.endTime}</Moment></div>
            </div>
            <div className="tenant-info">
              <div className="label">Pin Code:</div>
              <div className="info">{meeting.pinCode}</div>
            </div>
          </div>
          {!(meeting.meetingSpace&&meeting.meetingSpace.name)?
            <div className="row">
              <div className="d-flex justify-content-between">
                <div className="mb-3 poppins">Select APG Accesses required for this meeting. {needSync?<FontAwesomeIcon className="ms-1" icon="triangle-exclamation"/>:null}</div>
                {needSync?
                  <div className="col-md-4 text-end"><span onClick={syncMeeting} className="ms-3 add-btn sync-button">Sync <FontAwesomeIcon className="ms-4" icon="rotate" spin={isSyncing?true:false}/></span></div>
                :null}
              </div>
              {meeting.tenant.privilegeGroups.map(apg => {
                return <div className={"col-6 col-md-3"}>
                  <div className="apg-list d-flex" onClick={() => updateAPG(apg.accessPrivilegeGroupID)}>
                    <div className={"circle " + (meeting.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apg.accessPrivilegeGroupID)?"included":"")}>{(meeting.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apg.accessPrivilegeGroupID)?<FontAwesomeIcon icon="circle-check" />:"")}</div>
                    <div>{apg.accessPrivilegeGroup.name}</div>
                  </div>
                </div>
              })}
            </div>
          :null}
          <div className="notifications">
            {meeting && meeting.status==="Reserved"&&moment(meeting.endTime).isAfter()?
              <div className={"send-notifications " + (meeting.notifications.length?"border-bottom":"")}>
                <div className="text-center mb-3">Send Meeting Notifications</div>
                <div className="row justify-content-center">
                {["SMS", "Email"].map(type => {
                  return <div key={type} className={"col-6 col-md-3"}>
                    <div className={"select-btn " + (type===notificationType?"active":"")} onClick={() => setNotificationType(type)}>{type}</div>
                  </div>
                })}
                </div>
                {notificationType?
                  <div className="mt-2">
                    {notificationType==="SMS"?
                      <>
                      <div className="mb-3">
                        <small onClick={() => setShowSample(!showSample)} className="d-block mb-2 cursor-pointer link-underline">Sample SMS</small>
                        {showSample?
                        <small>
                          Meeting with Your Company Name, Monday January 1 1:30pm–2:30pm. 31 Spencer, Brooklyn, NY 11205, 8th Fl. Conference Room. Use the PIN 1234 at entrance, elevator and meeting space.
                        </small>
                        :null}
                      </div>
                      <small className="d-block mb-1">Comma Separated List; Use country code ie. 17185556666 for US Number</small>
                      <textarea rows="2" id={'smsPhone'} name={'smsPhone'} className="form-control" value={notificationNumbers} onChange={(e) => setNotificationNumbers(e.target.value)} />
                      </>
                    :null}
                    {notificationType==="Email"?
                      <>
                      <div className="mb-3">
                        <small onClick={() => setShowSample(!showSample)} className="d-block mb-2 cursor-pointer link-underline">Sample Email</small>
                        {showSample?
                        <small>
                          <div>
                            <p>You have been invited to join a meeting with Your Company Name on Monday, January 1, 2025 from 1:30pm to 2:30pm.</p>
                            <p>The meeting will be at 31 Spencer, Brooklyn, NY 11205 in 8th Fl. Conference Room.</p>
                            <p>Use the PIN 1234 to enter the building, at the elevator and to access the meeting space.</p>
                            <p>Please reach out with any questions.</p>
                            <div>Best regards,</div>
                            <div>Your Company Name</div>
                          </div>
                        </small>
                        :null}
                      </div>
                      <small className="d-block mb-1">Comma Separated List of Emails</small>
                      <textarea rows="2" id={'smsPhone'} name={'smsPhone'} className="form-control" value={notificationEmails} onChange={(e)=> setNotificationEmails(e.target.value)} />
                      </>
                    :null}
                    <div className="text-left mt-2 pe-3">
                      <div className="d-flex">
                        <Button className="btn btn-secondary ms-auto" onClick={sendMeetingNotifications} loading={isSendingNotifications} disabled={(notificationType==="Email"&&!notificationEmails)||(notificationType==="SMS"&&!notificationNumbers)}>Submit</Button>
                      </div>
                    </div>
                  </div>
                :null}
              </div>
            :null}
            {meeting.notifications.length?
              <div className="sent-notifications">
                <div className="text-center mb-3">Sent Notifications</div>
                 {meeting.notifications.map(notification => {
                  return <div key={notification.id} className="d-flex flex-wrap justify-content-between mb-1">
                    <div>{notification.email||notification.phone} {!notification.dateSent?<FontAwesomeIcon id={"error"+notification.id} className="ms-1 error" icon="exclamation-circle"/>:""}</div>
                    <div><Moment format="MM/DD/YY h:mma">{notification.dateCreated}</Moment></div>
                    {notification.error?
                      <UncontrolledTooltip target={"error"+notification.id}>
                        {notification.error}
                      </UncontrolledTooltip>
                      :
                    null}
                  </div>
                 })}       
              </div>
            :null}
          </div>        
          </div>
        </>
        :isLoaded?null:<Loader />}
      </div>
    </div>
    <Modal isOpen={editMeetingModal} toggle={modalEditMeetingToggle} size="lg" centered={true} modalClassName="modal-update-meeting modal-full fadeInDownModal">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Edit Meeting</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalEditMeetingToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {error?<Alert color="danger">{error}</Alert>:null}
        <UpdateMeeting meeting={meeting} meetingUpdated={meetingUpdated} />
      </ModalBody>
    </Modal>
  </div>
}
export default Meeting;