import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { getAuthorization, config } from './models/account';

import Login from "./views/Login";
import PasswordReset from "./views/PasswordReset";
import Tenant from "./views/Tenant";
import Tenants from "./views/Tenants";
import EditTenant from "./views/EditTenant";
import Cardholders from "./views/Cardholders";
import EditCardholder from "./views/EditCardholder";
import AdminUsers from "./views/AdminUsers";
import Meetings from "./views/Meetings";
import Meeting from "./views/Meeting";
import Users from "./views/Users";
import NotFoundView from "./views/404";

function AppRouter() {
    return (
    <Routes>
      <Route path="/" element={ <PrivateRoute admin={true}><Tenants /></PrivateRoute> } />
      <Route path="/login" element={ <Login /> } />
      <Route path="/passwordreset" element={ <PasswordReset /> } />
      <Route path="/cardholders/:id/edit" element={ <PrivateRoute admin={true}><EditCardholder /></PrivateRoute> } />
      <Route path="/tenants/:id/cardholders" element={ <PrivateRoute admin={true}><Cardholders /></PrivateRoute> } />
      <Route path="/tenants/:id/edit" element={ <PrivateRoute superAdmin={true}><EditTenant /></PrivateRoute> } />
      <Route path="/tenants/:id" element={ <PrivateRoute admin={true}><Tenant /></PrivateRoute> } />
      <Route path="/tenants" element={ <PrivateRoute superAdmin={true}><Tenants /></PrivateRoute> } />
      <Route path="/meetings" element={ <PrivateRoute superAdmin={true}><Meetings key={1} viewAll={true} /></PrivateRoute> } />
      <Route path="/:tenantId/meetings" element={ <PrivateRoute><Meetings key={2} /></PrivateRoute> } />
      <Route path="/:tenantId/meetings/:meetingId" element={ <PrivateRoute><Meeting /></PrivateRoute> } />
      <Route path="/adminusers" element={ <PrivateRoute superAdmin={true}><AdminUsers /></PrivateRoute> } />
      <Route path="/users" element={ <PrivateRoute superAdmin={true}><Users /></PrivateRoute> } />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  )
}

export const appAuth = {
  authorization: null,
  isAuthenticated() {
    return this.authorization != null
  },
  checkAuthorization(cb) {
    let authorization = getAuthorization();
    if ( authorization && authorization.refreshToken !== null ) {
      this.authorization = authorization;
    } else {
      this.authorization = null;
    }
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.authorization = null;
    setTimeout(cb, 100);
  }
};

config.onLogout = () => {
    appAuth.signout();
    window.location.href = "/";
  };

function PrivateRoute(props) {
  appAuth.checkAuthorization();
  let location = useLocation();
  return (
    appAuth.isAuthenticated() ?
      (!props.admin&&!props.superAdmin) || (appAuth.authorization.roles.includes('Admin')) || (props.admin && appAuth.authorization.tenantRoles.length)?
         props.children             
      :
        <Navigate
          to={{pathname: "/"}}
          state={{pathname: location.pathname}}
        />
     : (
      <Navigate
        to={{pathname: "/login"}}
        state={{pathname: location.pathname}}
      />
    )
  );
}

export default AppRouter