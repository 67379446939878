function FormatDate(props) {
  if(props.date){
    let date = new Date(props.date);
    if(!isNaN(date)){
      date = date.toJSON().slice(0, 10);
      date = date.slice(5, 7) + '/'
      + date.slice(8, 10) + '/'
      + date.slice(0, 4);
    } else {
      date = null;
    }
    return date;
  }
}

export default FormatDate;