import React, { useState, useEffect } from 'react';
import { getSettings } from './../models/settings';
import { getMeetingSpaces, getAvailableMeetings, patchMeeting } from './../models/meetings';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { DayPicker } from "react-day-picker";
import Moment from "react-moment";
import moment from "moment";
import Button from '../components/button';
import Loader from '../components/loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/bundle';

const UpdateMeeting = (props) => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSavingMeeting, setIsSavingMeeting] = useState(false);
  const [spaces, setSpaces] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState({});
  const [availablemeetings, setAvailableMeetings] = useState(null);
  const [timeslots, setTimeslots] = useState({});
  const [viewMoreAM, setViewMoreAM] = useState(false);
  const [viewMorePM, setViewMorePM] = useState(false);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate ] = useState(moment().toDate());
  const { tenantId } = useParams();

  useEffect(() => {
    retrieveMeetingSpaces();
    if(!props.meeting){
      retrieveAvailableMeetings(selectedDate);
    }
    getSettings()
    .then(settings => {
      setSettings(settings);
    })
  }, []);

  useEffect(() => {
    if(settings && props.meeting){
      setDate(moment(props.meeting.startTime).toDate(), true);
      setSelectedSlots(setExistingResTimeslots());    
    }
  }, [settings]);

  const retrieveMeetingSpaces = () => {
    getMeetingSpaces()
      .then(
        result => {
          setSpaces(result);
          setIsLoaded(true);
          setError(null);
        },
        error => {
          setError(error);
          setIsLoaded(true);
        }
      )
  }
      
  const retrieveAvailableMeetings = (dt, initialLoad) => {
    setIsLoaded(false);
    getAvailableMeetings(moment(dt).format('YYYY-MM-DD'), ((props.meeting&&props.meeting.id)||null))
    .then(
      result => {
      setIsLoaded(true);
      setAvailableMeetings(result);
      setTimeslots([]);
      if(props.meeting){
        selectSpace(selectedSpace||(props.meeting.meetingSpace), result, initialLoad);
      } else {
        setSelectedSpace(null);
      }      
      setError(null);
    },
    error => {
      setIsLoaded(true);
      setError(error);
    })
  }

  const setDate = (date, initialLoad) => {
    setSelectedDate(date);
    retrieveAvailableMeetings(date, initialLoad);
  }

  const setExistingResTimeslots = () => {
    const slotIncrement = parseInt(settings&&settings.meetingSlotIncrement.value);
    let meetingStartTime = props.meeting.startTime;
    let meetingEndTime = props.meeting.endTime;
    let diffMinutes = moment(meetingEndTime).diff(meetingStartTime,'minutes');
    let setSelectedTimeSlotsFromExisting = {};
    if(diffMinutes > slotIncrement){
      let existingTimeSlotsNum = diffMinutes/slotIncrement;
      for (let i = 0; i < existingTimeSlotsNum; i++){
        setSelectedTimeSlotsFromExisting[moment(meetingStartTime).add(i*slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss')] = {selected: true, available: true};
      }
    } else {
      setSelectedTimeSlotsFromExisting[moment(meetingStartTime).format('YYYY-MM-DDTHH:mm:ss')] = {selected: true, available: true}
    }
    return setSelectedTimeSlotsFromExisting;
  }

  const selectSpace = (space, meetingData, initialLoad) => {
    setSelectedSpace(space);
    setSlots(space&&space.id, meetingData);
    if(!initialLoad){
      setSelectedSlots({});
    }
  }

  const setSlots = (spaceId, meetingData) => {
    let filterMeetingDate = meetingData||availablemeetings
    let meetingSpace = filterMeetingDate.filter(meet => meet.meetingSpace.id===spaceId)[0];
    if(meetingSpace){
      let amSlots = meetingSpace.timeSlots.filter(slot=>moment(slot.timeSlot).format("A")==="AM");
      let pmSlots = meetingSpace.timeSlots.filter(slot=>moment(slot.timeSlot).format("A")==="PM");
      setTimeslots({AM: amSlots, PM: pmSlots});
    }  
  }  

  const selectSlots = (slot) => {
    const slotIncrement = parseInt(settings&&settings.meetingSlotIncrement.value);
    if(slot.available){
      if(selectedSlots[slot.timeSlot]&&selectedSlots[slot.timeSlot].selected){
        let prevSlot = moment(slot.timeSlot).subtract(slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss');
        let nextSlot = moment(slot.timeSlot).add(slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss');
        if((selectedSlots[prevSlot]&&selectedSlots[prevSlot].selected)&&(selectedSlots[nextSlot]&&selectedSlots[nextSlot].selected)){
          setSelectedSlots({});
        } else {
          setSelectedSlots(prevState => ({...prevState, [slot.timeSlot]: {selected: false, available: slot.available}}));
        }
      } else {
        let selectedSlotsLength = Object.keys(selectedSlots).filter(slot => selectedSlots[slot].selected && selectedSlots[slot].available);
        if(selectedSlotsLength.length===1){
          let allTimeslots = timeslots.AM.concat(timeslots.PM);
          let timeSlot1 = moment(slot.timeSlot).isBefore(selectedSlotsLength[0])?slot.timeSlot:selectedSlotsLength[0];
          let timeSlot2 = moment(selectedSlotsLength[0]).isBefore(slot.timeSlot)?slot.timeSlot:selectedSlotsLength[0];
          allTimeslots.forEach(allTimeslot => {
            if(moment(allTimeslot.timeSlot).isBetween(timeSlot1, timeSlot2)){
              setSelectedSlots(prevState => ({...prevState, [allTimeslot.timeSlot]: {selected: true, available: slot.available}}));
            }
            setSelectedSlots(prevState => ({...prevState, [slot.timeSlot]: {selected: true, available: slot.available}}));
          });
        } else {
          let prevSlot = moment(slot.timeSlot).subtract(slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss');
          let nextSlot = moment(slot.timeSlot).add(slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss');
          if(slot.available && ((selectedSlots[prevSlot]&&selectedSlots[prevSlot].selected)||(selectedSlots[nextSlot]&&selectedSlots[nextSlot].selected))){
            setSelectedSlots(prevState => ({...prevState, [slot.timeSlot]: {selected: true, available: slot.available}}));
          } else {
            setSelectedSlots({[slot.timeSlot]: {selected: true, available: slot.available}});
          }
        }
      }
    } else {
      setSelectedSlots({[slot.timeSlot]: {selected: true, available: slot.available}});
    }
  }

  const handleSaveMeeting = (data) => {
    setIsSavingMeeting(true);
    const slotIncrement = parseInt(settings&&settings.meetingSlotIncrement.value);
    const sortedTimes = Object.keys(selectedSlots).filter(slot=>selectedSlots[slot].selected).sort();
    const startTime = sortedTimes[0];
    const endTime = moment(sortedTimes[sortedTimes.length - 1]).add(slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss');
    const internalMeetingSpaceId = settings&&settings.meetingSpaceInternalID.value;
    const meetingSpaceId = selectedSpace.id===parseInt(internalMeetingSpaceId)?null:selectedSpace.id;
    let meetingId;
    data = {meetingSpaceId, startTime, endTime }
    if(props.meeting){
      meetingId = props.meeting.id;
    } else {
      meetingId = 0;
      data = {...data, tenantId}
    }      
    patchMeeting(meetingId, data)
    .then( result => {
      if(props.meetingUpdated){
        props.meetingUpdated();
      } else {
        navigate(`/${tenantId}/meetings/${result.id}`);
      }
      setIsSavingMeeting(false);
    },
    error => {
      setError(error);
      setIsSavingMeeting(false);
    })
  }
  const slotIncrement = parseInt(settings&&settings.meetingSlotIncrement.value);
  const sortedTimes = Object.keys(selectedSlots).filter(slot=>selectedSlots[slot].selected).sort();
  const startTime = sortedTimes[0];
  const endTime = moment(sortedTimes[sortedTimes.length - 1]).add(slotIncrement, 'm').format('YYYY-MM-DDTHH:mm:ss');
  return <div className="update-meeting">
    <div>        
      {error?<Alert color="danger">{error}</Alert>:null}
      {isLoaded && spaces?
      <>
      <div className="d-flex text-center mb-3 align-items-center dayNavigation">
        <div className="goYesterday">
          <button className="btn btn-outline-secondary btn-sm" onClick={()=>setDate(moment(selectedDate).subtract(1, 'day'))} disabled={moment(selectedDate).startOf('day').subtract(1, 'day').isBefore(moment().startOf('day'))}>
            <FontAwesomeIcon className="mie-1" icon={"chevron-left"} /> 
          </button>
        </div>
       <div id="PopoverFocus" className="text-center">
          <input
          className="text-center"
          type="text"
          value={moment(selectedDate).format("MM/DD/yyyy")}
          placeholder="MM/dd/yyyy"
          readOnly
          />
       </div>
       <UncontrolledPopover
        placement="bottom"
        target="PopoverFocus"
        trigger="legacy"
        className="date-popup"
      >
        <PopoverBody>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={setDate}
            disabled= {{ before: moment().toDate() }}
          />
        </PopoverBody>
      </UncontrolledPopover>
      <div className="goTomorrow">
          <button className="btn btn-outline-secondary btn-sm" onClick={()=>setDate(moment(selectedDate).add(1, 'day'))}>
            <FontAwesomeIcon className="mis-1" icon={"chevron-right"} />
          </button>
        </div>
      </div>
      <div className="position-relative">
        <div className="locations">
         <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            spaceBetween={50}
            slidesPerView={1}
            breakpoints={{
              // when window width is >= 640px
              992: {
                slidesPerView: 3,
              }
            }}
          >
            {spaces.map(space => {
              return space.active?
               <SwiperSlide className={"meeting-space apg-list poppins d-flex " + ((selectedSpace&&selectedSpace.id)===space.id?"active":"")} key={space.id} onClick={() => (selectedSpace&&selectedSpace.id)!==space.id?selectSpace(space):null}>
                  <div className={"circle " + ((selectedSpace&&selectedSpace.id)===space.id?"included":"")}>{(selectedSpace&&selectedSpace.id)===space.id?<FontAwesomeIcon icon="circle-check" />:""}</div>
                  <div>
                    <div>{space.name}</div>
                    <div>{space.location}</div>
                  </div>
                </SwiperSlide>
              :null
            })}
          </Swiper>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      {timeslots.AM&&timeslots.PM?
      <>
      <div className="poppins mt-3 text-center">
      {Object.keys(selectedSlots).some(slot=>selectedSlots[slot].selected)?
        `${moment(selectedDate).format("MMMM DD, YYYY")} - ${selectedSpace.name} Starts ${moment(startTime).format("h:mma")} ends ${moment(endTime).format("h:mma")}` 
        :
        (selectedSpace && !((timeslots.AM&&timeslots.AM.length)&&(timeslots.PM&&timeslots.PM.length))?
          null
        :
          "Click the time slots to select the times you'd like for the meeting"
        )
      }
      </div>
      <div className="grid-2">
        {selectedSpace && !((timeslots.AM&&timeslots.AM.length)||(timeslots.PM&&timeslots.PM.length))?
          null
        :
        <>
        <div className={"times-container poppins " + (!(timeslots.AM&&timeslots.AM.length)?"d-none d-md-block":"")}>
          <div className="times times-left row">
          {timeslots.AM&&timeslots.AM.length?
            <>
             <div className="col-12 mb-2">AM</div>
             <div onClick={() => setViewMoreAM(!viewMoreAM)} className="mb-2 small text-end cursor-pointer">{viewMoreAM?"View Less":"View More"}</div>
             {timeslots.AM.map((slot,i) => {
              return viewMoreAM || (moment(slot.timeSlot).hour()>=7)?
                <div key={i} className="col-2">
               <div className={"slot " + (selectedSlots&&selectedSlots[slot.timeSlot]&&selectedSlots[slot.timeSlot].selected?"selected ":"") + (!slot.available?"disabled":"")} onClick={() => selectSlots(slot)}><Moment format="h:mm">{slot.timeSlot}</Moment></div>
             </div>
             :null
             })}
             </>
          :null}
          </div>
        </div>
        <div className="times-container poppins">
          <div className="times row">
            {timeslots.PM&&timeslots.PM.length?
              <>
              <div className="col-12 mb-2">PM</div>
              {timeslots.PM&&timeslots.PM.map((slot,i) => {
                return viewMorePM || (moment(slot.timeSlot).hour()<19)?
                  <div key={i} className="col-2">
                    <div className={"slot " + (selectedSlots&&selectedSlots[slot.timeSlot]&&selectedSlots[slot.timeSlot].selected?"selected ":"") + (!slot.available?"disabled":"")} onClick={() => selectSlots(slot)}><Moment format="h:mm">{slot.timeSlot}</Moment></div>
                  </div>
                :null
              })}
              <div onClick={() => setViewMorePM(!viewMorePM)} className="small text-end cursor-pointer">{viewMorePM?"View Less":"View More"}</div>
              </>
            :null}
          </div>
        </div>
        </>
        }
        </div>
        </>
      :
        <div className="col-10 mx-auto text-center"><Alert className="mx-aut mt-3">Please choose meeting space above to see available timeslots</Alert></div>
      }
      {selectedSpace && ((timeslots.AM&&timeslots.AM.length)||(timeslots.PM&&timeslots.PM.length))?
        <div className="text-left mt-2 pe-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto" onClick={handleSaveMeeting} disabled={!Object.keys(selectedSlots).some(slot=>selectedSlots[slot].selected&&selectedSlots[slot].available)} loading={isSavingMeeting}>Save Meeting</Button>
          </div>
        </div>
      :
        selectedSpace && !((timeslots.AM&&timeslots.AM.length)&&(timeslots.PM&&timeslots.PM.length))?
          <div className="col-10 mx-auto text-center"><Alert color="warning" className="mx-aut mt-3">There are no available timeslots for your selections</Alert></div>
        :null
      }
        </>
      :isLoaded?null:<Loader />}
    </div>
  </div>
}
export default UpdateMeeting;