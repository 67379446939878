import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appAuth } from "../router";
import { getTenants, patchTenant } from '../models/tenants';
import { getCompanyFieldOptions } from '../models/settings';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalHeader, ModalBody, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useForm } from "react-hook-form";
import Button from '../components/button';
import Loader from '../components/loader';
import PageHeader from '../components/pageHeader';
import TableSearch from '../components/tableSearch';

const Tenants = (props) => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isLoaded, setIsLoaded] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [companyIDs, setCompanyIDs] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isSavingTenant, setIsSavingTenant] = useState(false);
  const [isDeactivatingTenant, setIsDeactivatingTenant] = useState(false);
  const [addTenantModal, setAddTenantModal] = useState(false);
  const [deleteTenantModal, setDeleteTenantModal] = useState(false);
  const [deleteTenant, setDeleteTenant] = useState(false);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    appAuth.checkAuthorization();
    let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
    let tenantAdmin = appAuth.authorization&&appAuth.authorization.tenantRoles;
    if(isSuperAdmin || tenantAdmin.length>1){
      retrieveTenants();
    } else {
      navigate(`/tenants/${tenantAdmin[0].tenantId}`);
    }
  }, [])

  const retrieveTenants = () => {
    getTenants()
      .then(
        result => {
          setIsLoaded(true);
          setTenants(result);
          setFilteredTenants(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const retrieveCompanyIds = () => {
    getCompanyFieldOptions()
    .then(result => {
      setCompanyIDs(result);
    },
    error => {
      setError(error);
    })
  }

  const modalTenantToggle = () => {
    setAddTenantModal(!addTenantModal);
    reset();
    setSelectedCompany("");
    if(!companyIDs.length){
      retrieveCompanyIds();
    }
  }
  
  const handleSaveTenant = (data) => {
    setIsSavingTenant(true);
    let companyId = companyIDs.find(company => company.name===selectedCompany)
    data = {name: data.name, location: data.location, accessControlCompanyId: companyId.id}
    patchTenant(0, data)
    .then( result => {
      navigate(`/tenants/${result.id}/edit`);
      setIsSavingTenant(false);
    },
    error => {
      setError(error);
      setIsSavingTenant(false);
    })
  }

  const deactivateTenant = (e, tenant) => {
    setIsDeactivatingTenant(true);
    patchTenant(tenant.id, {active: !tenant.active})
    .then( result => {
      setIsDeactivatingTenant(false);
      retrieveTenants();
      modalDeleteTenantToggle();
      setError(null);
    },
    error => {
      setIsDeactivatingTenant(false);
      setError(error);
    })
  }

  const modalDeleteTenantToggle = (e, tenant) => {
    setDeleteTenant(tenant?tenant:null);
    setDeleteTenantModal(!deleteTenantModal);
  }

  const updateFilteredTenants = (updatedFilteredTenants) => {
    setFilteredTenants(updatedFilteredTenants);
  }

  let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
  let hasInactive = tenants&&tenants.some(tenant=>!tenant.active);
  return <div className={"container page-tenants"}>
    <div className="content">
      <PageHeader pageName="Tenants" />
      <div className="page-center">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="title col-md-4">{isSuperAdmin?"Manage Tenants":"Select Tenant"}</div>
          <div className="col-md-4"></div>
        </div>
        {error?<Alert color="danger">{error}</Alert>:null}
        <TableSearch list={tenants} displayFilteredList={updateFilteredTenants} filterBy={"name"} />
        <div className="proj-table">
          <table>
            <thead>
              <tr>
                <th className="w-50 libre">Company Name<div className="d-md-none small">Location</div></th>
                <th className="w-25 d-none d-md-th libreItalic">Location</th>
                {isSuperAdmin?<th className="text-end pb-3"><span onClick={modalTenantToggle} className="add-btn">Add New Tenant <FontAwesomeIcon className="ms-4" icon="plus"/></span></th>:null}
              </tr>
            </thead>
            <tbody>
              {isLoaded?
                filteredTenants.length?
                  filteredTenants.map((tenant, i) => {
                    return tenant.active || (includeInactive && !tenant.active)? 
                     <tr key={i} className={!tenant.active?"inactive":""}>
                      <td className="t-name">
                        <Link className="libre" to={`/tenants/${tenant.id}`}>{tenant.name}</Link>
                        <div className="d-block d-md-none small libreItalic">{tenant.location}</div>
                      </td>
                      <td className="d-none d-md-td libreItalic">{tenant.location}</td>
                      {isSuperAdmin?
                      <td>
                        <div className="d-flex justify-content-end">
                          {tenant.active?<Link className="action me-2" to={`/tenants/${tenant.id}/edit`}><FontAwesomeIcon icon="pen"/></Link>:null}
                          <div className="action" onClick={() => modalDeleteTenantToggle(null,tenant)}>{tenant.active?<FontAwesomeIcon icon="trash-can"/>:<FontAwesomeIcon icon="toggle-on"/>}</div>
                        </div>
                      </td>
                    :null}
                    </tr>
                    :null
                  })
              :null
            :<tr><td className="pt-4" colSpan="4"><Loader /></td></tr>}
            </tbody>
          </table>
          <div className="mt-3 poppins">{hasInactive?<div className="cursor-pointer" onClick={() => setIncludeInactive(!includeInactive)}>{includeInactive?"Hide Inactive":"Include Inactive"}</div>:null}</div>
        </div>
      </div>
    </div>
    <Modal isOpen={addTenantModal} toggle={modalTenantToggle} centered={true} modalClassName="modal-add-tenant modal-full fadeInDownModal">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Add Tenant</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalTenantToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        <form className="row" onSubmit={handleSubmit(handleSaveTenant)}>
          <div className="form-group col-sm">
            <label htmlFor="name">Name</label>
            <input type="text" {...register("name", { required: true })} className={"form-control " + (errors.name?"invalid":"") } name="name" />
          </div>
          <div className="form-group col-sm">
            <label htmlFor="location">Location</label>
            <input type="text" {...register("location", { required: true })} className={"form-control " + (errors.location?"invalid":"")} name="location"/>
          </div>
          <div className="form-group col-sm">
            <label htmlFor="location">CO ID</label>
            <UncontrolledDropdown name="accessControlCompanyId" className="company-id-dropdown poppins">
              <DropdownToggle caret className="form-control">{selectedCompany?selectedCompany:"Company Id"}</DropdownToggle>
              <DropdownMenu>
                {companyIDs.length && companyIDs.map(id => {
                  return <DropdownItem key={id.id} onClick={() => setSelectedCompany(id.name)}>{id.name}</DropdownItem>;
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </form>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto" onClick={handleSubmit(handleSaveTenant)} loading={isSavingTenant}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <Modal isOpen={deleteTenantModal} toggle={modalDeleteTenantToggle} centered={true} modalClassName="modal-delete-tenant modal-full fadeInDownModal">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">{deleteTenant&&deleteTenant.active?"Deactivate":"Activate"} Tenant</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalDeleteTenantToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {error?<Alert color="danger">{error}</Alert>:null}
        <div className="poppins">Are you sure you want to {deleteTenant&&deleteTenant.active?"deactivate":"activate"} {deleteTenant&&deleteTenant.name}?</div>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto" onClick={() => deactivateTenant(null, deleteTenant)} loading={isDeactivatingTenant}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </div>
}
export default Tenants;