import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import Button from "../components/button";
import { Alert } from "reactstrap";
import { appAuth } from "../router";
import { login, forgotPassword } from './../models/account';

const Login = (props) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [credentials, setCredentials] = useState({
    userName: "",
    password: ""
  });
  const [email, setEmail] = useState("");
  const [passwordReset, setPasswordReset] = useState(null);
  const [resetError, setResetError] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (credentials.userName && credentials.password) {
      handleLogin();
    }
  }

  const handleLogin = () => {
    setIsSubmitting(true);
    login(credentials.userName, credentials.password)
      .then(
        result => {
          if (result) {
            appAuth.checkAuthorization(() => {
              setRedirectToReferrer(true);
            });
          }
        },
        error => {
          setError(error);
          setIsSubmitting(false);
        }
      )
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setCredentials(preValue => { return {...preValue, [name]: value}})
  }

  const handleForgotPassword = () => {
    setResetError(null);
    setIsSubmitting(true);
    forgotPassword(email)
    .then(
      result => {
        setResetPasswordSuccess(true);
        setIsSubmitting(false);
        setEmail("");
      },
      error => {
        setResetError(error);
        setIsSubmitting(false);
        setResetPasswordSuccess(false);
      }
    )
  }

  let location = useLocation();
  location = location.state || {};
  let from = location.pathname ||  "/" ;
  if (redirectToReferrer) return <Navigate to={from} replace={true} />;
  return <div className={"container page-login"}>
    <div className="vh-100 d-flex flex-column align-items-center text-center">
      <div className="col-sm-6">
        <div className="location mb-4">The Spencer Tower</div>
        <h1 className="mb-4 libreItalic">Login with your credentials</h1>
        {error?<Alert color="danger">{error}</Alert>:null}
        {passwordReset?
        <>
        {resetError?<Alert color="danger">{resetError}</Alert>:null}
        {resetPasswordSuccess?<Alert color="success">Reset Password Email sent</Alert>:null}
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <Button className="btn-primary round-button white-spinner mx-auto mb-3 mt-3" onClick={handleForgotPassword} loading={isSubmitting}>Reset Password</Button>
        {!isSubmitting?<div className="cursor-pointer poppins" onClick={() => setPasswordReset(false)}>Cancel</div>:null}
        </>
        :
        <form onSubmit={handleSubmit}>
          <input type="text" name="userName" placeholder="Name" onChange={handleChange} className={"form-control poppins mb-3"} />
          <input type="password" name="password" placeholder="Password" onChange={handleChange} className={"form-control poppins mb-3"} />
          <Button type="button" className="btn-link d-block poppins" onClick={() => setPasswordReset(true)}>Forgot Password?</Button>
          <Button type="submit" className="submit-btn btn-primary round-button white-spinner" loading={isSubmitting}>Sign In</Button>
        </form>
        }
      </div>
    </div>
  </div>
}
export default Login;