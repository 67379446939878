import { 
  getMeetingSpaces as GetMeetingSpaces,
  getMeetingSpace as GetMeetingSpace,
  getMeetings as GetMeetings,
  getAvailableMeetings as GetAvailableMeetings,
  getMeeting as GetMeeting,
  patchMeeting as PatchMeeting,
  updateMeetingAccessPrivilegeGroup as UpdateMeetingAccessPrivilegeGroup,
  removeMeetingAccessPrivilegeGroup as RemoveMeetingAccessPrivilegeGroup,
  meetingNotifications as MeetingNotifications,
} from '../api';

export function getMeetingSpaces() {
  return new Promise((resolve, reject) => {
    GetMeetingSpaces()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getMeetingSpace(spaceId) {
  return new Promise((resolve, reject) => {
    GetMeetingSpace(spaceId)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getMeetings(tenantId, dtFrom, dtTo, meetingSpaceId) {
  return new Promise((resolve, reject) => {
    GetMeetings(tenantId, dtFrom, dtTo, meetingSpaceId)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getAvailableMeetings(dt, replacingMeetingId) {
  return new Promise((resolve, reject) => {
    GetAvailableMeetings(dt, replacingMeetingId)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getMeeting(id) {
  return new Promise((resolve, reject) => {
    GetMeeting(id)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function patchMeeting(id, data){
  let patch = [];
  Object.keys(data).map(key => {
    patch.push({path: `/${key}`, op: 'add', value: data[key]});
  })
  return new Promise((resolve, reject) => {
    PatchMeeting(id, patch)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function updateMeetingAccessPrivilegeGroup(meetingId, apgId){
  return new Promise((resolve, reject) => {
    UpdateMeetingAccessPrivilegeGroup(meetingId, apgId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function removeMeetingAccessPrivilegeGroup(meetingId, apgId){
  return new Promise((resolve, reject) => {
    RemoveMeetingAccessPrivilegeGroup(meetingId, apgId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function meetingnotifications(meetingId, data){
  return new Promise((resolve, reject) => {
    MeetingNotifications(meetingId, data)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}