import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appAuth } from "../router";
import { logout } from '../models/account';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from 'reactstrap';
import LogRocket from 'logrocket';

function PageHeader(props) {
  const [isOpen, setIsOpen] = useState(false);
  appAuth.checkAuthorization();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production'){
      LogRocket.init('nnj5bn/temp-check-for-errors', {
        network: {
          requestSanitizer: request => {
            if (request.headers['Authorization']) {
              request.headers['Authorization'] = request.headers['Authorization'].substring(0, 10);;
            }
            if (request.url.toLowerCase().indexOf('token') !== -1) {
              // ignore the request response pair
              return null;
            }
            return request;
          },
        },
      });
      LogRocket.identify(appAuth.authorization.userId, {
        name: 'Spencer Tower Doors',
        email: appAuth.authorization.username,
      });
    }  
  }, [])

  const toggle = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    logout();
  }
  let { state } = useLocation();
  const { id, tenantId } = useParams();

let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
return <div className="page-header">
<Navbar expand="md" dark={false}>
  <NavbarBrand tag={Link} className="building-title libreBold" to={id&&!isSuperAdmin?`/tenants/${id}`:"/tenants"}>The Spencer Tower</NavbarBrand>
  <NavbarToggler onClick={toggle} />
  <Collapse isOpen={isOpen} navbar>
    <Nav className="ms-auto menu" navbar>
      {isSuperAdmin?
      <>
      <NavItem>
        <NavLink className={props.pageName==="Tenants"?"active":""} tag={Link} to="/tenants">Tenants</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className={props.pageName==="AllMeetings"?"active":""} tag={Link} to="/meetings">All Meetings</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className={props.pageName==="AUsers"?"active":""} tag={Link} to="/adminusers">Admin Users</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className={props.pageName==="Users"?"active":""} tag={Link} to="/users">Users</NavLink>
      </NavItem>
      </>
      :
      <>
      {props.pageName!=="Tenants"?
      <NavItem>
        <NavLink className={props.pageName==="Tenant"?"active":""} tag={Link} to={props.pageName==="editCardholder"&&(state&&state.tenantId)?`/tenants/${state.tenantId}`:(id&&props.pageName!=="editCardholder"?`/tenants/${id||tenantId}`:"/tenants")}>My Account</NavLink>
      </NavItem>
      :null}
      {((id || tenantId) && props.pageName!=="editCardholder")||(props.pageName==="editCardholder" && (state&&state.tenantId))?
      <NavItem>
        <NavLink className={props.pageName==="Cardholders"?"active":""} tag={Link} to={props.pageName==="editCardholder"&&(state&&state.tenantId)?`/tenants/${state.tenantId}/cardholders`:`/tenants/${id||tenantId}/cardholders`}>Cardholders</NavLink>
      </NavItem>
      :null}
      {((id || tenantId) && props.pageName!=="editCardholder")||(props.pageName==="editCardholder" && (state&&state.tenantId))?
      <NavItem>
        <NavLink className={props.pageName==="Meetings"?"active":""} tag={Link} to={props.pageName==="editCardholder"&&(state&&state.tenantId)?`/${state.tenantId}/meetings`:`/${id||tenantId}/meetings`}>Meetings</NavLink>
      </NavItem>
      :null}
      </>
      }
      <NavItem>
        <NavLink className="mobile" onClick={handleLogout}>Logout</NavLink>
      </NavItem>
    </Nav>
    <NavbarText className="desktop logout poppins" onClick={handleLogout}>Logout <FontAwesomeIcon icon="user" /></NavbarText>
  </Collapse>
</Navbar>
</div>
}

export default PageHeader;