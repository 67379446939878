import {
  getTenants as GetTenants,
  getTenant as GetTenant,
  getTenantUnlockList as GetTenantUnlockList,
  tenantUnlock as TenantUnlock,
  patchTenant as PatchTenant,
  updateAccessPrivilegeGroup as UpdateAccessPrivilegeGroup,
  removeAccessPrivilegeGroup as RemoveAccessPrivilegeGroup,
  addTenantUser as AddTenantUser,
  removeTenantUser as RemoveTenantUser
} from '../api';

export function getTenants() {
  return new Promise((resolve, reject) => {
    GetTenants()
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getTenant(id) {
  return new Promise((resolve, reject) => {
    GetTenant(id)
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getTenantUnlockList(id) {
  return new Promise((resolve, reject) => {
    GetTenantUnlockList(id)
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            ['doors', 'floors'].map(type => {
              result[type].map(list => {
                list.filterName = type==='doors'?list.name:list.name + " - " + list.elevatorName;
              })
            })
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function tenantUnlock(id, data){
  return new Promise((resolve, reject) => {
    TenantUnlock(id, data)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function patchTenant(id, data){
  let patch = [];
  Object.keys(data).map(key => {
    if (data[key]!==null) {
      patch.push({path: `/${key}`, op: 'add', value: data[key]});
    }
  })
  return new Promise((resolve, reject) => {
    PatchTenant(id, patch)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function updateAccessPrivilegeGroup(tenantId, apgId){
  return new Promise((resolve, reject) => {
    UpdateAccessPrivilegeGroup(tenantId, apgId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function removeAccessPrivilegeGroup(tenantId, apgId){
  return new Promise((resolve, reject) => {
    RemoveAccessPrivilegeGroup(tenantId, apgId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function addTenantUser(tenantId, userId, role){
  return new Promise((resolve, reject) => {
    AddTenantUser(tenantId, userId, role)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function removeTenantUser(tenantId, userId){
  return new Promise((resolve, reject) => {
   RemoveTenantUser(tenantId, userId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}