import {
  findCardholder as FindCardholder,
  getTenantCardholders as GetTenantCardholders,
  getCardholder as GetCardholder,
  patchCardholder as PatchCardholder,
  updateCardholderAccessPrivilegeGroup as UpdateCardholderAccessPrivilegeGroup,
  removeCardholderAccessPrivilegeGroup as RemoveCardholderAccessPrivilegeGroup,
} from '../api';

function processCardholder(cardholder) {
  cardholder.name = cardholder.firstName + " " + cardholder.lastName;
  return cardholder;
}

export function findCardholder(cardCode, pinCode) {
  return new Promise((resolve, reject) => {
    FindCardholder(cardCode, pinCode)
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            processCardholder(result);
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getTenantCardholders(tenantId) {
  return new Promise((resolve, reject) => {
    GetTenantCardholders(tenantId)
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            result.map(cardholder => processCardholder(cardholder))
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getCardholder(id) {
  return new Promise((resolve, reject) => {
    GetCardholder(id)
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            processCardholder(result);
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function patchCardholder(cardholderId, tenantId, data){
  let patch = [];
  Object.keys(data).map(key => {
    if (data[key]!==null) {
      patch.push({path: `/${key}`, op: 'add', value: data[key]});
    }
  })
  return new Promise((resolve, reject) => {
    PatchCardholder(cardholderId, tenantId, patch)
      .then(
        result => result.success ? resolve(processCardholder(result.result)) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function updateCardholderAccessPrivilegeGroup(cardholderId, apgId){
  return new Promise((resolve, reject) => {
    UpdateCardholderAccessPrivilegeGroup(cardholderId, apgId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}

export function removeCardholderAccessPrivilegeGroup(cardholderId, apgId){
  return new Promise((resolve, reject) => {
    RemoveCardholderAccessPrivilegeGroup(cardholderId, apgId)
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
      )
  })
}