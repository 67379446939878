import React, { useState, useEffect } from 'react';
import { getAdminUsers, changeUserRole, deleteUser } from '../models/users';
import { registerUser, setUserPassword } from '../models/account';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalBody } from 'reactstrap';
import { useForm } from "react-hook-form";
import Button from '../components/button';
import Loader from '../components/loader';
import PageHeader from '../components/pageHeader';
import TableSearch from '../components/tableSearch';

const AdminUsers = (props) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2} = useForm();
  const [isLoaded, setIsLoaded] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [filteredAdminUsers, setFilteredAdminUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isSettingPassword, setIsSettingPassword] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState(false);
  const [selectedEditUser, setSelectedEditUser] = useState(false);
  const [addUserError, setAddUserError] = useState(null);
  const [deleteUserError, setDeleteUserError] = useState(null);
  const [setPasswordError, setSetPasswordError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    retrieveAdminUsers();
  }, [])

  const retrieveAdminUsers = () => {
    getAdminUsers()
      .then(
        result => {
          setIsLoaded(true);
          setAdminUsers(result);
          setFilteredAdminUsers(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const modalUserToggle = () => {
    setAddUserModal(!addUserModal);
    reset();
  }
  
  const handleAddUser = (data) => {
    // data = {email: data.email, password: data.password, confirmPassword: data.password}
    setIsAddingUser(true);
    registerUser(data.email, data.password, data.password)
    .then( result => {
      changeUserRole(result.id, "Admin")
      .then(result => {
        setIsAddingUser(false);
        setAddUserError(null);
        setAddUserModal(false);
        retrieveAdminUsers();
      },
      error => {
        setIsAddingUser(false);
        setAddUserError(error);
      })
    },
    error => {
      setAddUserError(error);
      setIsAddingUser(false);
    })
  }
  
  const modalDeleteUserToggle = (user) => {
    setSelectedDeleteUser(user?user:null);
    setDeleteUserModal(!deleteUserModal);
  }

  const modalEditUserToggle = (e, user) => {
    setSelectedEditUser(user?user:null);
    setEditUserModal(!editUserModal);
    reset2();
  }

  const handleSetPassword = (data) => {
    setIsSettingPassword(true);
    if(selectedEditUser){
      setUserPassword(selectedEditUser.id, data.password, data.confirmPassword)
      .then( result => {
        setIsSettingPassword(false);
        setSetPasswordError(null);
        setEditUserModal(false);
      },
      error => {
        setSetPasswordError(error);
        setIsSettingPassword(false);
      })
    }
  }

  const handleDeleteUser = (id) => {
    setIsDeletingUser(true);
    deleteUser(id)
    .then( result => {
      setIsDeletingUser(false);
      setDeleteUserError(null);
      setDeleteUserModal(false);
      retrieveAdminUsers();
    },
    error => {
      setDeleteUserError(error);
      setIsDeletingUser(false);
    })
  }

  const updateFilteredAdminUsers = (updatedFilteredAdminUsers) => {
    setFilteredAdminUsers(updatedFilteredAdminUsers);
  }

  return <div className={"container page-users"}>
    <div className="content">
      <PageHeader pageName="AUsers" />
      <div className="page-center">
        <div className="title">Manage Admin Users</div>
        {error?<Alert color="danger">{error}</Alert>:null}
        <TableSearch list={adminUsers} displayFilteredList={updateFilteredAdminUsers} filterBy={"userName"} />
        <div className="apg-table">
          <table>
            <thead>
              <tr>
                <th className="w-50 w-md-25 libre">Username</th>
                <th className="text-end pb-3"><span onClick={modalUserToggle} className="add-btn">Add Admin User <FontAwesomeIcon className="ms-4" icon="plus"/></span></th>
              </tr>
            </thead>
            <tbody>
              {isLoaded?
              filteredAdminUsers.length?
                filteredAdminUsers.map((user, i) => {
                  return <tr key={i}>
                    <td className="libre break-word">{user.userName}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                      <div className="action me-2" onClick={() => modalEditUserToggle(null, user)}><FontAwesomeIcon icon="pen"/></div>
                        <div className="action" onClick={() => modalDeleteUserToggle(user)}><FontAwesomeIcon icon="trash-can"/></div>
                      </div>
                    </td>
                  </tr>
                })
              :null
              :<tr><td className="pt-4" colSpan="4"><Loader /></td></tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Modal isOpen={addUserModal} toggle={modalUserToggle} centered={true} modalClassName="modal-add-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Add Cardholder</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalUserToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {addUserError?<Alert color="danger">{addUserError}</Alert>:null}
        <form className="row" onSubmit={handleSubmit(handleAddUser)}>
          <div className="form-group col-sm">
            <label htmlFor="name">Email</label>
            <input type="email" {...register("email", { required: true,pattern: {value: /\S+@\S+\.\S+/,message: "Entered value does not match email format"} })} className={"form-control " + (errors.email?"invalid":"") } name="email" />
          </div>
          <div className="form-group col-sm">
            <label htmlFor="name">Password</label>
            <input type="password" {...register("password", { required: true })} className={"form-control " + (errors.password?"invalid":"") } name="password" />
          </div>
        </form>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto"  onClick={handleSubmit(handleAddUser)} loading={isAddingUser}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <Modal isOpen={editUserModal} toggle={modalEditUserToggle} centered={true} modalClassName="modal-delete-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Edit User</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalEditUserToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {deleteUserError?<Alert color="danger">{setPasswordError}</Alert>:null}
        <form className="row" onSubmit={handleSubmit2(handleSetPassword)}>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password"  {...register2("password", { required: true })} id="password" name="password" className={"form-control" + (errors.password?"invalid":"")} />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input type="password"  {...register2("confirmPassword", { required: true })} id="confirmPassword" name="confirmPassword" className={"form-control" + (errors.confirmPassword?"invalid":"")}/>
          </div>
        </form>
        <div className="d-flex mt-3">
          <Button type="secondary" onClick={handleSubmit2(handleSetPassword)} className="btn btn-secondary ms-auto" loading={isSettingPassword}>Save</Button>
        </div>
      </ModalBody>
    </Modal>
    <Modal isOpen={deleteUserModal} toggle={modalDeleteUserToggle} centered={true} modalClassName="modal-delete-cardholder">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Delete User</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalDeleteUserToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {deleteUserError?<Alert color="danger">{deleteUserError}</Alert>:null}
        <div className="poppins">Are you sure you want to delete {selectedDeleteUser&&selectedDeleteUser.userName}?</div>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button onClick={()=> handleDeleteUser(selectedDeleteUser.id)} className="btn btn-secondary ms-auto" loading={isDeletingUser}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </div>
}
export default AdminUsers;