import React, { Component } from 'react';
import Button from '../components/button';
import { Link } from 'react-router-dom';

class NotFoundView extends Component {
  render() {
    return (
      <div className={'container-page'}>
        <div className={"container text-center pt-5"}>
          <h1 className="text-center mb-4 poppins">404 - Page Not Found</h1>
          <Link to="/tenants"><Button className="btn-primary">Home</Button></Link>
        </div>
      </div>
    )
  }
}

export default NotFoundView;