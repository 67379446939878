import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import Button from "../components/button";
import { Alert } from "reactstrap";
import { resetPassword } from './../models/account';

const Login = (props) => {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState(null);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    passwordConfirm: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  let location = useLocation();
  useEffect(() => {
    if(location.search){
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      setCode(code);
    }
  }, []);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if(credentials.email && credentials.password && credentials.passwordConfirm && (credentials.password === credentials.passwordConfirm)){
      setIsSubmitting(true);
      let reset = {
        Email: credentials.email,
        Password: credentials.password,
        ConfirmPassword: credentials.passwordConfirm,
        Code: code
      }
      resetPassword(reset)
      .then(
        result => {
          setMessage({type: 'success', message:'Your password has been reset.'});
          setIsSubmitting(false);
        },
        error => {
          setMessage({type: 'danger', message:error});
          setIsSubmitting(false);
        }
        )
      } else {
      setMessage({type: 'danger', message:"Please make sure all fields are filled out and passwords match."});
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setCredentials(preValue => { return {...preValue, [name]: value}})
  }

  return <div className={"container page-login"}>
    <div className="vh-100 d-flex flex-column align-items-center text-center">
      <div className="col-sm-6">
        <div className="location mb-4">The Spencer Tower</div>
        <h1 className="mb-3 libreItalic">Reset Password</h1>
        {message?
          <>
            <Alert color={message.type}>{message.message}</Alert>
            {message.type === 'success'? <p><Link to="/login" className="btn btn-primary mx-auto mb-3 d-inline-block">Login</Link></p> :null}
          </>
          :null}

          {!code?
            <Alert color="danger">There seems to be a problem with the link. Please generate a new password reset link.</Alert>
          :null}

          <form onSubmit={handleResetPassword} name="form" novalidate>
            {code && !(message && message.type==='success')?
            <div>
              <div className="poppins mb-3">Minimum of 6 or more characters. With at least 1 uppercase, 1 lowercase and 1 digit.</div>
              <div className="form-group mb-2">
                <input type="email" onChange={handleChange} className="form-control" id="email" name="email" required placeholder="Email"/>
              </div>
              <div className="form-group mb-2">
                <input type="password" onChange={handleChange} className="form-control" id="password" name="password" required placeholder="Password"/>
              </div>
              <div className="form-group mb-2 ">
                <input type="password" onChange={handleChange} className="form-control" id="passwordConfirm" name="passwordConfirm" required placeholder="Confirm Password"/>
              </div>
              <div className="form-group">
                <Button loading={isSubmitting} type="submit" className="btn white-spinner btn-primary mx-auto">Submit</Button>
              </div>
            </div>
            :null}
          </form>
      </div>
    </div>
  </div>
}
export default Login;