import React, { useState, useEffect } from 'react';
import { appAuth } from "../router";
import { useParams } from 'react-router-dom';
import { getMeetings, patchMeeting } from '../models/meetings';
import { getTenant } from '../models/tenants';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalBody } from 'reactstrap';
import Button from '../components/button';
import Loader from '../components/loader';
import UpdateMeeting from '../components/updateMeeting';
import PageHeader from '../components/pageHeader';
import Moment from "react-moment";
import moment from "moment";

const Meetings = (props) => {
  let { state } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [tenantName, setTenantName] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [createMeetingModal, setCreateMeetingModal] = useState(false);
  const [deleteMeetingModal, setDeleteMeetingModal] = useState(false);
  const [meetingToDelete, setMeetingToDelete] = useState(false);
  const [isDeletingMeeting, setIsDeletingMeeting] = useState(false);
  const [includeCanceled, setIncludeCanceled] = useState(false);
  const [error, setError] = useState(null);
  const { tenantId } = useParams();

  useEffect(() => {
    retrieveMeetings();
    if(!(state && state.tenantName) && !props.viewAll){
      retrieveTenant();
    }
  }, [])

  const retrieveTenant = () => {
    getTenant(tenantId)
      .then(
        result => {
          setError(null);
          setTenantName(result.name);
        },
        error => {
          setError(error);
        }
      )
  }

  const retrieveMeetings = () => {
    getMeetings(tenantId)
      .then(
        result => {
          setIsLoaded(true);
          setMeetings(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const deleteMeeting = (e, meeting) => {
    setIsDeletingMeeting(true);
    const data = {status: 'Canceled' }
    patchMeeting(meeting.id, data)
    .then( result => {
      setIsDeletingMeeting(false);
      retrieveMeetings();
      modalDeleteMeetingToggle();
      setError(null);
    },
    error => {
      setIsDeletingMeeting(false);
      setError(error);
    })
  }

  const modalDeleteMeetingToggle = (e, meeting) => {
    setMeetingToDelete(meeting?meeting:null);
    setDeleteMeetingModal(!deleteMeetingModal);
  }

  const modalCreateMeetingToggle = () => {
    setCreateMeetingModal(!createMeetingModal);
  }

  appAuth.checkAuthorization();
  let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
  let hasCanceled = meetings&&meetings.some(meeting=>meeting.status!=="Reserved");
  return <div className={"container page-tenants"}>
    <div className="content">
      <PageHeader pageName={props.viewAll?"AllMeetings":"Meetings"} />
      <div className="page-center">
        <div className="row justify-content-between">
          <div className="col-md-4">
            {isSuperAdmin&&!props.viewAll?<Link className="d-none d-md-block poppins" to={(state&&state.comingFrom==="view")||!(state&&state.comingFrom)?`/tenants/${tenantId}`:`/tenants/${tenantId}/edit`}><FontAwesomeIcon className="me-1" icon="arrow-left"/>Back to tenant</Link>:null}
          </div>
          <div className="title col-md-4">View {props.viewAll?"All":((state&&state.tenantName)||tenantName)} Meetings</div>
          <div className="col-md-4"></div>
        </div>
        {error?<Alert color="danger">{error}</Alert>:null}
        <div className="proj-table">
          <table>
            <thead>
              <tr>
                <th className={"w-50 libre " + (props.viewAll?"w-md-20":"w-md-25")}>Meeting Space
                  {props.viewAll?<div className="d-md-none small">Tenant</div>:null}
                  <div className="d-md-none small">Location</div>
                  <div className="d-md-none small">Date</div>
                  <div className="d-md-none small">Time</div>
                </th>
                {props.viewAll?<th className="w-20 d-none d-md-th libreItalic">Tenant</th>:null}
                <th className={"d-none d-md-th libreItalic " + (props.viewAll?"w-20":"w-25")}>Location</th>
                <th className={"d-none d-md-th libreItalic " + (props.viewAll?"w-20":"w-25")}>Date</th>
                <th className={"d-none d-md-th libreItalic " + (props.viewAll?"w-20":"w-25")}>Time</th>
                <th className="text-end pb-3 fw-normal">{!props.viewAll?<span onClick={modalCreateMeetingToggle} className="add-btn">New Meeting <FontAwesomeIcon className="ms-4" icon="plus"/></span>:null}</th>
              </tr>
            </thead>
            <tbody>
              {isLoaded?
                meetings.length?
                  meetings.map((meeting, i) => {
                    return meeting.status==="Reserved" || (includeCanceled && meeting.status==="Canceled")? 
                     <tr key={i} className={meeting.status==="Canceled"?"inactive":""}>
                      <td className="t-name">
                        <Link className="libre" state={{comingFromAllMeetings: props.viewAll}} to={`/${meeting.tenantId}/meetings/${meeting.id}`}>{(meeting.meetingSpace&&meeting.meetingSpace.name)||"Internal"}</Link>
                        {props.viewAll?<div className="d-block d-md-none small libreItalic">{meeting.tenant.name}</div>:null}
                        <div className="d-block d-md-none small libreItalic">{(meeting.meetingSpace&&meeting.meetingSpace.location)||"Internal"}</div>
                        <div className="d-block d-md-none small libreItalic"><Moment format="MM/DD/YY">{meeting.startTime}</Moment></div>
                        <div className="d-block d-md-none small libreItalic"><Moment format="h:mma">{meeting.startTime}</Moment> - <Moment format="h:mma">{meeting.endTime}</Moment></div>
                      </td>
                      {props.viewAll?<td className="d-none d-md-td libreItalic">{meeting.tenant.name}</td>:null}
                      <td className="d-none d-md-td libreItalic">{(meeting.meetingSpace&&meeting.meetingSpace.location)||"Internal"}</td>
                      <td className="d-none d-md-td libreItalic"><Moment format="MM/DD/YY">{meeting.startTime}</Moment></td>
                      <td className="d-none d-md-td libreItalic"><Moment format="h:mma">{meeting.startTime}</Moment> - <Moment format="h:mma">{meeting.endTime}</Moment></td>
                      <td>
                        <div className="d-flex justify-content-end">
                          {meeting.status==="Reserved"&&moment(meeting.endTime).isAfter()?
                            <>
                            <Link className="action me-2" state={{comingFromAllMeetings: props.viewAll}} to={`/${meeting.tenantId}/meetings/${meeting.id}`}><FontAwesomeIcon icon="pen"/></Link>
                            <div className="action" onClick={() => modalDeleteMeetingToggle(null,meeting)}><FontAwesomeIcon icon="trash-can"/></div>
                            </>
                          :null}
                        </div>
                      </td>
                    </tr>
                    :null
                  })
              :null
            :<tr><td className="pt-4" colSpan="4"><Loader /></td></tr>}
            </tbody>
          </table>
          <div className="mt-3 poppins">{hasCanceled?<div className="cursor-pointer" onClick={() => setIncludeCanceled(!includeCanceled)}>{includeCanceled?"Hide Canceled":"Include Canceled"}</div>:null}</div>
        </div>
      </div>
    </div>
    <Modal isOpen={createMeetingModal} toggle={modalCreateMeetingToggle} size="lg" centered={true} modalClassName="modal-update-meeting modal-full fadeInDownModal">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Create Meeting</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalCreateMeetingToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {error?<Alert color="danger">{error}</Alert>:null}
        <UpdateMeeting />
      </ModalBody>
    </Modal>
    <Modal isOpen={deleteMeetingModal} toggle={modalDeleteMeetingToggle} centered={true} modalClassName="modal-delete-tenant modal-full fadeInDownModal">
      <div className="modal-header" stop-event="touchend">
        <h5 className="modal-title">Delete Meeting</h5>
        <button type="button" className="close btn" aria-label="Close" onClick={modalDeleteMeetingToggle}><FontAwesomeIcon icon="xmark"/></button>
      </div>
      <ModalBody>
        {error?<Alert color="danger">{error}</Alert>:null}
        <div className="poppins">Are you sure you want to cancel the meeting scheduled to take place in {meetingToDelete&&meetingToDelete.meetingSpace.location} on <Moment format="MM/DD/YY">{meetingToDelete&&meetingToDelete.startTime}</Moment> at <Moment format="h:mma">{meetingToDelete&&meetingToDelete.startTime}</Moment>?</div>
        <div className="text-left mt-3">
          <div className="d-flex">
            <Button className="btn btn-secondary ms-auto" onClick={() => deleteMeeting(null, meetingToDelete)} loading={isDeletingMeeting}>Confirm</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </div>
}
export default Meetings;