import { 
  getAdminUsers as GetAdminUsers,
  getUsers as GetUsers,
  getUser as GetUser,
  changeUserRole as ChangeUserRole,
  deleteUser as DeleteUser 
} from '../api';

export function getAdminUsers() {
  return new Promise((resolve, reject) => {
    GetAdminUsers()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getUsers() {
  return new Promise((resolve, reject) => {
    GetUsers()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getUser(id) {
  return new Promise((resolve, reject) => {
    GetUser(id)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function changeUserRole(userId, role) {
  return new Promise((resolve, reject) => {
    ChangeUserRole(userId, role)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}

export function deleteUser(userId) {
  return new Promise((resolve, reject) => {
    DeleteUser(userId)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
      );
  })
}