import {
  getSettings as GetSettings,
  getCompanyFieldOptions as GetCompanyFieldOptions,
  getAccessPrivilegeGroup as GetAccessPrivilegeGroup,
  resyncCardholders as ResyncCardholders,
  resyncMeetings as ResyncMeetings
} from '../api';

function processSettings(result) {
  let settings = {};
  result.map(setting => {
    settings[setting.Key] = {};
    settings[setting.Key]["prevValue"] = setting.Value;
    settings[setting.Key]["value"] = setting.Value;
    return setting;
  })
  return settings;
}

export function getSettings() {
  return new Promise((resolve, reject) => {
    GetSettings()
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(processSettings(result));
          }
          return reject(result.Message);
        },
        error => reject(error)
      );
  })
}

export function getCompanyFieldOptions() {
  return new Promise((resolve, reject) => {
    GetCompanyFieldOptions()
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function getAccessPrivilegeGroup(resync) {
  return new Promise((resolve, reject) => {
    GetAccessPrivilegeGroup(resync)
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function resyncCardholders() {
  return new Promise((resolve, reject) => {
    ResyncCardholders()
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}

export function resyncMeetings() {
  return new Promise((resolve, reject) => {
    ResyncMeetings()
      .then(
        result => {
          if (typeof result.Message === 'undefined') {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
      );
  })
}
