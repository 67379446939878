import React, { useState, useEffect } from 'react';
import { getTenant, patchTenant, updateAccessPrivilegeGroup, removeAccessPrivilegeGroup } from '../models/tenants';
import { getAccessPrivilegeGroup } from '../models/settings';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Modal, ModalBody } from 'reactstrap';
import Button from '../components/button';
import Loader from '../components/loader';
import PageHeader from '../components/pageHeader';

const EditTenant = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [editTenantName, setEditTenantName] = useState(false);
  const [editTenantLocation, setEditTenantLocation] = useState(false);
  const [updateTenantName, setUpdateTenantName] = useState("");
  const [updateTenantLocation, setUpdateTenantLocation] = useState("");
  const [selectedAPG, setSelectedAPG] = useState(null);
  const [removeAPGModal, setRemoveAPGModal] = useState(false);
  const [accessPrivilegeGroups, setAccessPrivilegeGroups] = useState([]);
  const [acgModal, setAcgModal] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    retrieveTenant();
  }, []);

  const retrieveTenant = () => {
    getTenant(id)
      .then(
        result => {
          setIsLoaded(true);
          setTenant(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const retrieveACGs = (resync=false) => {
    if(resync){
      setIsSyncing(true);
    }
    getAccessPrivilegeGroup(resync)
    .then(result => {
      setIsSyncing(false);
      setAccessPrivilegeGroups(result);
    },
    error => {
      setIsSyncing(false);
      setError(error);
    })
  }

  const handleUpdateTenant = (type) => {
    let data;
    let updateMade;
    if(type==="name"){
      data = {name: updateTenantName};
      updateMade = !!updateTenantName;
      setEditTenantName(updateMade);
    } else if(type==="location"){
      data = {location: updateTenantLocation}
      updateMade = !!updateTenantLocation;
      setEditTenantLocation(updateMade);
    }
    if(updateMade){
      patchTenant(tenant.id, data)
      .then( result => {
        setTenant(result);
        setEditTenantName(type==="name"?false:editTenantName);
        setUpdateTenantName(type==="name"?"":updateTenantName);
        setEditTenantLocation(type==="location"?false:editTenantLocation);
        setUpdateTenantLocation(type==="location"?"":updateTenantLocation);
      },
      error => {
        setError(error);
      })
    }
  }

  const updateAPG = (apgId) => {
    if(tenant.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apgId)){
      removeAPG(null, apgId);
    } else {
      updateAccessPrivilegeGroup(tenant.id, apgId)
      .then( result => {
        setTenant(tenant => {return {...tenant, privilegeGroups: result}});
      },
      error => {
        setError(error);
      })
    }
  }

  const removeAPG = (e, apgId) => {
    removeAccessPrivilegeGroup(tenant.id, apgId?apgId:selectedAPG.accessPrivilegeGroupID)
    .then( result => {
      let t = {...tenant};
      t.privilegeGroups = result;
      setTenant(t);
      setSelectedAPG(null)
      setRemoveAPGModal(false);
    },
    error => {
      setError(error);
    })
  }

  const modalAddAPGToggle = () => {
    setAcgModal(!acgModal);
    if(!accessPrivilegeGroups.length){
      retrieveACGs();
    }
  }

  const modalRemoveAPGToggle = (e, apg) => {
    setSelectedAPG(apg?apg:null)
    setRemoveAPGModal(!removeAPGModal);
  }



  return <div className={"container page-edit-tenants"}>
    <div className="content">
      <PageHeader />
      <div className="page-center">
        <div className="row justify-content-between mb-4">
          <div className="col-md-4"><Link className="d-none d-md-block poppins" to="/tenants"><FontAwesomeIcon className="me-1" icon="arrow-left"/> Back to tenants</Link></div>
          <div className="title col-md-4">Edit Tenant</div>
          <div className="col-md-4"></div>
        </div>
        {error?<Alert color="danger">{error}</Alert>:null}
        {isLoaded? 
        tenant?
        <>
        <div className="row">
          <div className="col-md-6">
            <div className="tenant-info">
              <div className="label">Name:</div>
              {editTenantName?<input className="info form-control" defaultValue={tenant.name} onChange={(e)=>setUpdateTenantName(e.target.value)} />:<div className="info">{tenant.name}</div>}
              {editTenantName?<Button className="btn-secondary ms-3" onClick={()=>handleUpdateTenant('name')}>Save</Button>:<div className="action" onClick={()=>setEditTenantName(true)}><FontAwesomeIcon icon="pen"/></div>}
            </div>
            <div className="tenant-info">
              <div className="label">Location:</div>
              {editTenantLocation?<input className="info form-control" defaultValue={tenant.location} onChange={(e)=>setUpdateTenantLocation(e.target.value)} />:<div className="info">{tenant.location}</div>}
              {editTenantLocation?<Button className="btn-secondary ms-3" onClick={()=>handleUpdateTenant('location')}>Save</Button>:<div className="action" onClick={()=>setEditTenantLocation(true)}><FontAwesomeIcon icon="pen"/></div>}
            </div>
            <div className="tenant-info">
              <div className="label">CO ID:</div>
              <div className="info">{tenant.accessControlCompanyId}</div>
            </div>
          </div>
          <div className="col-md-6 text-center text-md-end mb-4">
            <Link state={{tenantName: tenant.name, comingFrom: "edit"}} to={`/${tenant.id}/meetings`}><Button className="btn-primary round-button">Meetings</Button></Link>
            <Link className="ms-3" state={{tenantName: tenant.name, comingFrom: "edit"}} to={`/tenants/${tenant.id}/cardholders`}><Button className="btn-primary round-button">Cardholders</Button></Link>
          </div>
        </div>
        <div className="apg-table collapse-th">
          <table>
            <thead>
              <tr>
                <th className="libre">Access Privilege Groups</th>
                <th className="text-end groups-sync">
                  <span onClick={modalAddAPGToggle} className="add-btn">Add Group <FontAwesomeIcon className="ms-4" icon="plus"/></span>
                  <span onClick={() => retrieveACGs(true)} className="ms-3 add-btn sync-button">Sync <FontAwesomeIcon className="ms-4" icon="rotate" spin={isSyncing?true:false}/></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {tenant.privilegeGroups.map((apg, i) => {
                  return <tr key={i}>
                    <td className="libre">{apg.accessPrivilegeGroup.name}</td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <div className="action" onClick={()=>modalRemoveAPGToggle(null, apg)}><FontAwesomeIcon icon="trash-can"/></div>
                      </div>
                    </td>
                  </tr>
                })}
            </tbody>
          </table>
          </div>
          <Modal isOpen={acgModal} toggle={modalAddAPGToggle} centered={true} size={"lg"} modalClassName="modal-add-apg">
            <div className="modal-header" stop-event="touchend">
              <h5 className="modal-title">Add Access Privilege Groups to <span className="secondary-color">{tenant.name}</span></h5>
              <button type="button" className="close btn" aria-label="Close" onClick={modalAddAPGToggle}><FontAwesomeIcon icon="xmark"/></button>
            </div>
            <ModalBody>
              <div className="row">
                {accessPrivilegeGroups.map(apg => {
                  return <div className={"col-6 col-lg-3"}>
                    <div className="apg-list d-flex" onClick={() => updateAPG(apg.id)}>
                      <div className={"circle " + (tenant.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apg.id)?"included":"")}>{(tenant.privilegeGroups.some(tpg => tpg.accessPrivilegeGroupID === apg.id)?<FontAwesomeIcon icon="circle-check" />:"")}</div>
                      <div>{apg.name}</div>
                    </div>
                  </div>
                })}
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={removeAPGModal} toggle={modalRemoveAPGToggle} centered={true} modalClassName="modal-remove-acg">
            <div className="modal-header">
              <h5 className="modal-title">Remove APG</h5>
              <button type="button" className="close btn" aria-label="Close" onClick={modalRemoveAPGToggle}><FontAwesomeIcon icon="xmark"/></button>
            </div>
            <ModalBody>
              <div className="poppins">Are you sure you want to remove {selectedAPG&&selectedAPG.accessPrivilegeGroup.name}?</div>
              <div className="text-left mt-3">
                <div className="d-flex">
                  <Button onClick={removeAPG} className="btn btn-secondary ms-auto">Confirm</Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          </>
          :null
        :<Loader />}
      </div>
    </div>
  </div>
}
export default EditTenant;