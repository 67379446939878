import React, { useState, useEffect } from 'react';
import { appAuth } from "../router";
import { getTenant, getTenantUnlockList, tenantUnlock } from './../models/tenants';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from 'reactstrap';
import Button from '../components/button';
import Loader from '../components/loader';
import PageHeader from '../components/pageHeader';
import TableSearch from '../components/tableSearch';

const Tenant = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [unlockList, setUnlockList] = useState(null);
  const [filteredUnlockList, setFilteredUnlockList] = useState(null);
  const [unlockedList, setUnlockedList] = useState({});
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    retrieveTenant();
  }, []);

  const retrieveTenant = () => {
    getTenant(id)
      .then(
        result => {
          retrieveTenantUnlockList(result.id);
          setTenant(result);
          setError(null);
        },
        error => {
          setError(error);
          setIsLoaded(true);
        }
      )
  }

  const retrieveTenantUnlockList = () => {
    getTenantUnlockList(id)
      .then(
        result => {
          setIsLoaded(true);
          setUnlockList(result);
          setFilteredUnlockList(result);
          setError(null);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const unlockDoor = (doorId,floorId,minutes) => {
    let data = {doorId, floorId, minutes};
    let changedId = doorId||floorId;
    tenantUnlock(id, data)
    .then(
      result => {
        setUnlockedList(ulist => { return {...ulist, [`${changedId}_${minutes}`]: true}});
        setTimeout(()=>{
          setUnlockedList(ulist => { return {...ulist, [`${changedId}_${minutes}`]: false}});
        }, minutes?60000:5000)
        setError(null);
      },
      error => {
        setError(error);
      }
    )
  }

  const updateFilteredUnlockList = (updatedFilteredUnlockList) => {
    setFilteredUnlockList(updatedFilteredUnlockList);
  }

  appAuth.checkAuthorization();
  let isSuperAdmin = appAuth.authorization&&appAuth.authorization.roles.includes('Admin');
  let tenantAdmin = appAuth.authorization&&appAuth.authorization.tenantRoles;
  return <div className={"container page-tenant"}>
    <div className="content">
      <PageHeader pageName="Tenant" />
      <div className="page-center">
        <div className="row justify-content-between mb-4">
          <div className="col-md-4">
            {isSuperAdmin?<Link className="d-none d-md-block poppins" to="/tenants"><FontAwesomeIcon className="me-1" icon="arrow-left"/> Back to tenants</Link>:null}
            {!isSuperAdmin && tenantAdmin.length>1?<Link className="d-none d-md-block poppins" to="/tenants">Switch Tenant Account</Link>:null}
          </div>
          <div className="title col-md-4">{isSuperAdmin?"View Tenant":"My Account"}</div>
          <div className="col-md-4"></div>
        </div>
        {error?<Alert color="danger">{error}</Alert>:null}
        {isLoaded && tenant?
        <>
        <div className="row">
          <div className="col-md-6">
            <div className="tenant-info">
              <div className="label">Name:</div>
              <div className="info">{tenant.name}</div>
            </div>
            <div className="tenant-info">
              <div className="label">Location:</div>
              <div className="info">{tenant.location}</div>
            </div>
          </div>
          <div className="col-md-6 mb-3 text-center text-md-end">
            {isSuperAdmin?<Link state={{tenantName: tenant.name, comingFrom: "view"}} to={`/${tenant.id}/meetings`}><Button className="btn-primary round-button">Meetings</Button></Link>:null}
            {isSuperAdmin?<Link className="ms-3" state={{tenantName: tenant.name, comingFrom: "view"}} to={`/tenants/${tenant.id}/cardholders`}><Button className="btn-primary round-button">Cardholders</Button></Link>:null}
          </div>
        </div>
        <TableSearch list={unlockList} displayFilteredList={updateFilteredUnlockList} filterBy={"filterName"} doors={true} />
        <div className="apg-table">
          <table>
            <thead>
              <tr>
                <th className="w-50 w-md-75 libre">Door Control</th>
                <th className="text-end libreItalic">Pulse</th>
                <th className="text-end libreItalic">Unlock<div className="small poppins">1 minute</div></th>
              </tr>
            </thead>
            <tbody>
              {filteredUnlockList && ['doors', 'floors'].map(type => {
                return filteredUnlockList[type].map((list, i) => {
                  return <tr key={list.id}>
                    <td className="libre">{list.name}{type==='floors'?" - " + list.elevatorName:null}</td>
                    <td>
                      {type==="doors"?
                      <div className="d-flex justify-content-end">
                        <div className="action" onClick={unlockedList[`${list.Id}_0`]?null:()=>unlockDoor((type==='doors'?list.Id:null),(type==='floors'?list.Id:null),0)}>{unlockedList[`${list.Id}_0`]?<FontAwesomeIcon icon="circle-check"/>:<FontAwesomeIcon icon="unlock-keyhole"/>}</div>
                      </div>
                      :null}
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <div className="action" onClick={unlockedList[`${list.Id}_1`]?null:()=>unlockDoor((type==='doors'?list.Id:null),(type==='floors'?list.Id:null),1)}>{unlockedList[`${list.Id}_1`]?<FontAwesomeIcon icon="circle-check"/>:<FontAwesomeIcon icon="key"/>}</div>
                      </div>
                    </td>
                  </tr>
                })
              })}
            </tbody>
          </table>
          </div>
          </>
        :isLoaded?null:<Loader />}
      </div>
    </div>
  </div>
}
export default Tenant;