import React, { useState } from 'react';

function TableSearch(props) {
  const [filterText, setFilterText] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    let shown = [];
    if(props.doors){
      shown = {};
      props.list && ['doors', 'floors'].map(type => {
        return shown[type] = props.list[type].filter(item => {
          return item[props.filterBy].toLowerCase().includes(value.toLowerCase()) ? item : null
        })
      })
    } else {
      shown = props.list && props.list.filter(item => {
        return item[props.filterBy].toLowerCase().includes(value.toLowerCase()) ? item : null
      })
    }
    setFilterText(value);
    props.displayFilteredList(shown);
  }

  return <div className="row justify-content-end mb-3">
    <div className="col-sm-4">
      <input className="form-control" value={filterText?filterText:''} placeholder="Search List" onChange={handleChange}/>
    </div>
  </div>
}

export default TableSearch;