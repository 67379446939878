import { 
  login as Login,
  logout as Logout,
  register as Register, 
  setUserPassword as SetUserPassword,
  forgotPassword as ForgotPassword,
  resetPassword as ResetPassword
 } from './../api';
import { api_url } from './../global-props';
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';


let tokensStorage = JSON.parse(localStorage.getItem('token_response'));
window.tokens = {
  refreshToken: tokensStorage ? tokensStorage.refresh_token : null,
  accessToken: tokensStorage ? tokensStorage.access_token : null,
  userId: tokensStorage ? tokensStorage.userId : null,
  url: tokensStorage ? tokensStorage.url : null,
  roles: tokensStorage ? tokensStorage.roles : null,
  tenantRoles: tokensStorage && tokensStorage.tenantRoles ? JSON.parse(tokensStorage.tenantRoles) : null,
  username: tokensStorage ? tokensStorage.userName : null,
  firstName: tokensStorage ? tokensStorage.firstName : null,
  lastName: tokensStorage ? tokensStorage.lastName : null,
  gender: tokensStorage ? tokensStorage.gender : null,
}

export const config = {
  onClearAuthorization: null,
  onLogout: null
}

export function getAuthorization() {
  return window.tokens;
}

function handleNewToken(response) {
  let refreshToken = response.refresh_token;
  let accessToken = response.access_token;
  let userId = response.userId;
  let url = response.url;
  let roles = response.roles ? JSON.parse(response.roles) : null;
  let tenantRoles = response.tenantRoles ? JSON.parse(response.tenantRoles) : null;
  let username = response.userName;
  let firstName = response.firstName;
  let lastName = response.lastName;
  let gender = response.gender;
  window.tokens = { ...window.tokens, refreshToken, accessToken, userId, roles, tenantRoles, username, firstName, lastName, gender, url };
  localStorage.setItem('token_response', JSON.stringify(response));
  return response;
}

function clearAuthorization() {
  let refreshToken = null;
  let accessToken = null;
  let userId = null;
  let roles = null;
  let url = null;
  let tenantRoles = null;
  let username = null;
  let firstName = null;
  let lastName = null;
  let gender = null;
  window.tokens = { ...window.tokens, refreshToken, accessToken, userId, roles, tenantRoles, username, firstName, lastName, gender, url };
  //appAuth.signout();
  // localStorage.clear();
  localStorage.removeItem('credentials');
  localStorage.removeItem('token_response');
  if ( config.onClearAuthorization ) {
    config.onClearAuthorization();
  }
}

//from https://stackoverflow.com/questions/21485545/is-there-a-way-to-tell-if-an-es6-promise-is-fulfilled-rejected-resolved
function MakeQueryablePromise(promise) {
  // Don't create a wrapper for promises that can already be queried.
  if (promise.isResolved) return promise;
  
  var isResolved = false;
  var isRejected = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  var result = promise.then(
     function(v) { isResolved = true; return v; }, 
     function(e) { isRejected = true; throw e; });
  result.isFulfilled = function() { return isResolved || isRejected; };
  result.isResolved = function() { return isResolved; }
  result.isRejected = function() { return isRejected; }
  return result;
}

export function renewAccessTokenP() {
  if (window.renewAccessTokenPromise && !window.renewAccessTokenPromise.isFulfilled()) {
    //console.log ('Reusing pending token promise');
    return window.renewAccessTokenPromise;
  }
  
  let pTmp = renewAccessToken();
  window.renewAccessTokenPromise = MakeQueryablePromise(pTmp);
  return window.renewAccessTokenPromise;
}

export function renewAccessToken(useCredentials = false) {
  let args = {};
  if (useCredentials) {
    let credentialsStorage = localStorage.getItem('credentials');
    if (!credentialsStorage) return handleLogout();
    let credentialsStorageO = JSON.parse(credentialsStorage);
    if (credentialsStorageO===null) {
      return handleLogout();
    }
    args = {grant_type: 'password', username: credentialsStorageO.userName, password: credentialsStorageO.passwd };
  } else {
    let refreshToken = '123456';
    let userId = window.tokens.userId;
    args = {refresh_token: refreshToken, grant_type: 'refresh_token', user_id: userId };
    if (refreshToken===null) {
      return renewAccessToken(true);
    }
  }

  let url = api_url + '../token';
  let body = Object.keys(args).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(args[key])).join('&');
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded'}
  const method = 'POST';

  return fetchJSON(url, { method: method, body: body, headers: headers, credentials: 'include' })
    .then(({ response, body }) => { handleNewToken(body); return response; })
    .catch(error => {
      if (error.status===400 && error.body.error === 'invalid_grant' ) {
        if (!useCredentials) return renewAccessToken(true);
        return handleLogout();
      } else {
        throw Error('An error occurred.')
      }
    });
}

const fetchJSONWithToken = (url, options = {}) => {
  const token = window.tokens.accessToken;
  let optionsWithToken = options
  if (token != null) {
    optionsWithToken = {...options,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
  return fetchJSON(url, optionsWithToken)
}

const shouldRefreshToken = error => error.response.status === 401 && error.body.Message === 'Authorization has been denied for this request.';

const fetchWrapper = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken: renewAccessTokenP
})

export function doRequest(url, args) {
  return fetchWrapper(url, args)
  .then(({ response, body }) => {
      if ( response.status >= 200 && response.status < 300 ) {
        if (body === '') {  return Promise.reject('An error occurred.'); }
        return body;
      }
      return Promise.reject('An error occurred. [' + response.status + ']');
    } )
  .catch(error => {
    if (typeof error === 'string') { return Promise.reject(error); }
    return Promise.reject(error.body && error.body ? error.body : 'An error occurred.');
  });
}

function processLogin(result) {
  handleNewToken(result);
}

function processLogout(result) {
  clearAuthorization();
  if ( config.onLogout ) {
    config.onLogout();
  }
}

function handleLogout() {
  logout();
}

export function login(username, password) {
  return new Promise((resolve, reject) => {
    Login({ username: username, password: password, grant_type: 'password' })
      .then(
        result => {
          if ( result.error ) {
            return reject(result.error_description);
          }
          processLogin(result);
          return resolve(result);
        },
        error => reject('Error')
    )
  })
}

export function logout() {
  return new Promise((resolve, reject) => {
    Logout()
    .then(
        result => result.success ? resolve(processLogout()) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}

export function registerUser(email, password, confirmPassword) {
  return new Promise((resolve, reject) => {
    Register({ email: email, password: password, confirmPassword: confirmPassword })
      .then(
        result => result.success ? resolve(result.result) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}

export function setUserPassword(userId, password, confirmPassword) {
  return new Promise((resolve, reject) => {
    SetUserPassword(userId, password, confirmPassword)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}

export function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    ForgotPassword(email)
      .then(
        result => {
          if ( typeof result.Message === 'undefined' ) {
            return resolve(result);
          }
          return reject(result.Message);
        },
        error => reject(error.Message)
    )
  })
}

export function resetPassword(reset) {
  return new Promise((resolve, reject) => {
    ResetPassword(reset)
      .then(
        result => result.success ? resolve(result) : reject(result.errorMessage),
        error => reject(error)
    )
  })
}